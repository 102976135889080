import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import NewOrder from '@material-ui/icons/FiberNew';
import SettingsApplication from '@material-ui/icons/SettingsApplications';
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");

const whiteIcon = require("../../Assets/Images/WhiteIcon.png");

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    height: '100%',
    width: '200px',
    backgroundColor: 'white'
  }
});

class Template extends React.Component {

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{width: '120px', minHeight: '100vh', backgroundColor: 'clear', display: 'inline-block', verticalAlign:'top'}}>
          <img style={{width: 50, height: 'auto', position: 'absolute', top: 45, left: 32.5}} src={whiteIcon} alt="..." />
          <div className="centerbuttons">
            <Link to="/photo-shoots" style={{color:'white', textDecoration: 'none'}}>
              <div style={{cursor: 'pointer', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'shooting' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <PhotoCamera size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  Photo Shoots
                </div>
              </div>
            </Link>
            <Link to="/order" style={{color:'white', textDecoration: 'none'}}>
              <div style={{cursor: 'pointer', width: '120px',  marginTop: '40%', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'order' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
                <div>
                  <NewOrder size="25" color="white" style={{color: 'white'}}/>
                </div>
                <div style={{marginTop: 4, color: 'white'}}>
                  New Order
                </div>
              </div>
            </Link>
            <div style={{cursor: 'pointer', marginTop: '40%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'ss' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
              <div>
                <Person size="25" color="white" style={{color: 'white'}}/>
              </div>
              <div style={{marginTop: 4, color: 'white'}}>
                Coming soon
              </div>
            </div>
            <div style={{cursor: 'pointer', marginTop: '40%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.page == 'ss' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
              <div>
                <SettingsApplication size="25" color="white" style={{color: 'white'}}/>
              </div>
              <div style={{marginTop: 4, color: 'white'}}>
                Coming soon
              </div>
            </div>
          </div>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
