import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

class TemporaryDrawer extends React.Component {

 fullList = side => (
    <div
      style={{width: '100%'}}
      role="presentation"
      onClick={() => this.props.main.setState({detailsOpen: false})}
      onKeyDown={() => this.props.main.setState({detailsOpen: false})}
    >
      <ListItem button key={"Shoot"}>
        <div style={{fontWeight: 'bold', fontSize: 18, fontFamily: 'arial'}}>Shoot #{this.props.mainScreen.state.requestName}</div>
      </ListItem>
      <Divider />
      <List>
        <ListItem button key={this.props.mainScreen.state.photographerName}>
        <ListItemIcon><img className={"buttonIcon"} style={{width: 25, height: 25}} src={cameraIcon} alt="..." /></ListItemIcon>
          <ListItemText primary={this.props.mainScreen.state.photographerName} />
        </ListItem>
        <ListItem button key={this.props.mainScreen.state.contactName}>
        <ListItemIcon><img className={"buttonIcon"} style={{width: 25, height: 25}} src={userIcon} alt="..." /></ListItemIcon>
          <ListItemText primary={this.props.mainScreen.state.contactName} />
        </ListItem>
        <ListItem button key={this.props.mainScreen.state.shootingLocation}>
        <ListItemIcon><img className={"buttonIcon"} style={{width: 25, height: 25}} src={locationIcon} alt="..." /></ListItemIcon>
          <ListItemText primary={this.props.mainScreen.state.shootingLocation} />
        </ListItem>
        <ListItem button key={this.props.mainScreen.state.dateAndTime}>
        <ListItemIcon><img className={"buttonIcon"} style={{width: 25, height: 25}} src={dateIcon} alt="..." /></ListItemIcon>
          <ListItemText primary={this.props.mainScreen.state.dateAndTime} />
        </ListItem>
        <ListItem button key={this.props.mainScreen.state.productName}>
        <ListItemIcon><img className={"buttonIcon"} style={{width: 25, height: 25}} src={imageIcon} alt="..." /></ListItemIcon>
          <ListItemText primary={this.props.mainScreen.state.productName} />
        </ListItem>
      </List>
    </div>
  );


  render() {
    const { classes, theme } = this.props;
    return (
      <Drawer anchor="top" open={this.props.main.state.detailsOpen} onClose={()=>this.props.main.setState({detailsOpen: false})}>
        {this.fullList('top')}
      </Drawer>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TemporaryDrawer);
