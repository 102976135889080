import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { withSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/ShoppingCart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import Lightbox from 'react-image-lightbox';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Map from './Map';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import Autocomplete from 'react-google-autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from 'material-ui-pickers';
import PersonIcon from '@material-ui/icons/Person';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CalendarTodayIcon from '@material-ui/icons/DateRange';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Notifications from '@material-ui/icons/Notifications';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import axios from 'axios';

const photographerImage = require("../../Assets/Images/photographerImage.png");

const saleBanner = require("../../Assets/Images/sale-banner.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");
const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const selectedIcon = require("../../Assets/Images/selected.png");
const unselectedIcon = require("../../Assets/Images/unselected.png");
const confirmedIcon = require("../../Assets/Images/confirmed.png");
const zoomInIcon = require("../../Assets/Images/zoomin_icon.png");

const black_logo = require("../../Assets/Images/black_logo.png");

const drawerWidth = 380;

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const GET_EDITED_ORGENIZED_IMAGES = 'https://wesnapp.co.il/py/server/get-edited-images-orgenized/'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'rgb(255,255,255)'
  },
  formControl: {
    margin: theme.spacing(3),
    textAlign: 'left',
    float: 'left'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'clear'
  },
  appBarShift: {
    width: mobile ? '0px' : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  text: {
    color: 'rgb(40,40,53)'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    borderRadius: 0,
    borderColor: 'rgb(246,247,251)'
  },
  drawerPaper: {
    width: mobile ? '100vw' : drawerWidth,
    height: mobile ? 'calc(100vh - 0px)' : '100vh',
    backgroundColor: 'rgb(246,247,251)',
    borderRadius: 0,
    borderColor: 'rgb(246,247,251)',
    borderRadius: !mobile ? '35px 0px 0px 35px' : '35px 35px 0px 0px',
    backgroundColor: 'rgb(238,242,249)'
  },
  drawerHeader: {
    display: 'flex',
    // alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    borderColor: 'rgb(246,247,251)'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
});

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 20,
    backgroundColor: 'rgb(249,250,252)'
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#04e259',
  },
})(LinearProgress);

// export default function PersistentDrawerRight() {

var lng = 0
var lat = 0

class ShootingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log("!@#!@#!@#");
  //   console.log(nextProps);
  //   console.log(nextState);
  //     if (this.state == null)
  //       return true;
  //
  //     if (this.state.open != nextState.open)
  //       return false;
  //
  //     return true;
  // }

  state = {
    paymentWithProduct: false,
    amountToCharge: 0,
    extraImagePrice: 0,
    extraImageDiscount: null,
    updateInProgress: false,
    requestPaid: null,
    loading: false,
    requestData: null,
    loadingData: true,
    open: mobile ? false : true,
    requestId: '',
    contactName: '',
    shootingLocation: '',
    dateAndTime: '',
    productName: '',
    firstSelection: false,
    avatar: '',
    mobilePhone: '',
    userComment: '',
    photographerName: '',
    numberOfImages: 0,
    downloadPercenteges: 0,
    confirmSelect: false,
    selectedImages: [],
    pricePerExtra: [],
    galleryIsOpen: false,
    galleryIndex: 0,
    imageHash: Date.now(),
    openConfirmDialog: false,
    openPaymentDialog: false,
    addingImagesToSalesforce: false,
    openReviewDialog: false,
    addingComment: false,
    openSnack: false,
    photographerRate: 0,
    photosRate: 0,
    imagesInPackage: 0,
    confirmSelect: false,
    selectedImages: [],
    selectedImagesString: '',
    avatar: '',
    selectedInThePast: [],
    imagesForGallery: [],
    openPaymentDialog: false,
    openConfirmDialog: false,
    addingImagesToSalesforce: false,
    editedImages: {thumbnail: [], originals: [], compressed: [], watermarked: [], loading: true, imagesForGallery: []},
    imagesToPayFor: "",
    // lat: 32.146611,
    // lng: 34.8519761
    lat: 0,
    lng: 0,

    sideMenuTab: 0,
    uploadingWatermark: false,

    logo_preferences: {opacity: 100, logoPosition: 'top_right', url: '', logoSize: 66}
  };

  handleChangeLogoPosition = event => {
   this.setState({logo_preferences: {opacity: this.state.logo_preferences.opacity, logoSize: this.state.logo_preferences.logoSize, logoPosition: event.target.value, url: this.state.logo_preferences.url}}, () => {
     this.updateLogoPreferences()
   })
 };

//  handleChangeOpacity = value => {
//   this.setState({opacity: value})
// };

handleChangeOpacity = (event, newValue) => {
 this.setState({logo_preferences: {opacity: newValue, logoSize: this.state.logo_preferences.logoSize, logoPosition: this.state.logo_preferences.logoPosition, url: this.state.logo_preferences.url}}, () => {
   this.updateLogoPreferences()
 })
};

handleChangeSize = (event, newValue) => {
  this.setState({logo_preferences: {opacity: this.state.logo_preferences.opacity, logoSize: newValue, logoPosition: this.state.logo_preferences.logoPosition, url: this.state.logo_preferences.url}}, () => {
    this.updateLogoPreferences()
  })
};

  handleChangeSideMenuTab = (event, newValue) => {
   // setValue(newValue);
   this.setState({sideMenuTab: newValue})
 };

  addReview = () => {
    this.setState({addingComment: true})

    var data = {requestId: this.state.requestId, data: {Comment_user__c: this.state.userComment} }
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
        this.setState({openSnack: true, addingComment: false})
        this.handleCloseReviewDialog()
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  setRatingForPhotographer = (value) => {
    this.setState({photographerRate: value})
    var data = {requestId: this.state.requestId, data: {Rating_Photographer__c: value}}
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  setRatingForPhotos = (value) => {
    this.setState({photosRate: value})
    var data = {requestId: this.state.requestId, data: {Rating_Shooting__c: value}}
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  handleOpenPaymentDialog = () => {
    this.setState({openPaymentDialog: true})
  }

  handleCloseReviewDialog = () => {
    this.setState({openReviewDialog: false})
  }

  handleCloseConfirmDialog = () => {
    this.setState({openConfirmDialog: false})
  }

  handleClosePaymentDialog = () => {
    this.getDataOfRequest()
    this.setState({openConfirmDialog: false})
    this.setState({openPaymentDialog: false})
  }

  handleDrawerOpen = () => {
    this.setState({open: true})
  };

  handleDrawerClose = () => {
    this.setState({open: false})
  };

  componentDidMount() {
    this.getDataOfRequest()
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getDateAndTime = async (dateAndTime) => {
    var dateObj = new Date(dateAndTime);
    var stringToReturn = ''
    stringToReturn += dateObj.getDate() + "/"
    stringToReturn += (dateObj.getMonth() + 1) + "/"
    stringToReturn += dateObj.getFullYear() + " "
    var hour = dateObj.getHours()
    if (String(hour).length < 2) {
      hour = "0" + hour
    }
    var min = dateObj.getMinutes()
    if (String(min).length < 2) {
      min = "0" + min
    }
    stringToReturn += hour + ":"
    stringToReturn += min
    this.setState({dateAndTime: stringToReturn})
  }

  getCoordinates = async (address) => {
    try {
      var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURI(address) + "&key=AIzaSyDJ7KNKLQOtQqlx871XHHU9EcZRt2K4qso"
      const response = await axios.get(link);
      console.log(response.data.results[0].geometry.location);
      // this.setState({lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng})
      lat = response.data.results[0].geometry.location.lat
      lng = response.data.results[0].geometry.location.lng
    } catch (err) {
        console.error(err);
    }
  }

  getDataOfRequest = async () => {
    try {
        var id = this.getParameterByName('id')
        if (id != null && id != undefined && id != "") {
          this.setState({requestId: id})
          var data = {id: id}
          const response = await axios.post("https://wesnapp.co.il/api/request/get-full-data", data);
          console.log(response.data);
          var dateAndTime = await this.getDateAndTime(response.data.request.Shooting_Date_Time__c)
          this.setState({loadingData: false})
          this.getCoordinates(response.data.request.ShootingStreet__c + " " + response.data.request.ShootingCity__c)
          var cn = response.data.contact.Name
          var avatar = ''
          if (cn.split(' ').length == 2) {
            avatar = cn.split(' ')[0][0] + cn.split(' ')[1][0]
            console.log(cn);
            console.log(cn.split(' ')[0]);
            console.log(cn.split(' ')[0][0]);
            console.log(cn.split(' ')[1][0]);
            console.log(avatar);
          }
          this.setState({avatar: avatar})
          var data = {id: response.data.contact.AccountId}
          const account_response = await axios.post("https://wesnapp.co.il/api/account/get-one-account", data);
          console.log("!@#");
          console.log(account_response.data[0].Logo_Preference__c);
          var logo_pre = JSON.parse(account_response.data[0].Logo_Preference__c)
          if (logo_pre != null && logo_pre != "") {
            this.setState({logo_preferences: logo_pre})
          }
          var extraImagePrice = response.data.product.Price_Per_Extra_Product__c
          var extraImageDiscount = account_response.data[0].Extra_Product_Discount__c
          if (extraImageDiscount != null) {
            console.log("discount");
            var old = response.data.product.Price_Per_Extra_Product__c
            var discount = 100 - extraImageDiscount
            console.log("old price", old);
            var extraImagePrice = (old * discount) / 100
            console.log(extraImagePrice);
          }
          this.setState({requestData: response.data,
            extraImagePrice: extraImagePrice,
            photographerName: response.data.request.Photographer_Name__c,
            requestName: response.data.request.Name,
            contactName: response.data.contact.Name,
            accountId: response.data.contact.AccountId,
            requestPaid: response.data.request.Paid__c,
            // requestPaid: false,
            extraImageDiscount: account_response.data[0].Extra_Product_Discount__c,
            numberOfImages: response.data.request.Number_of_Images_for_Customer__c,
            mobilePhone: response.data.contact.MobilePhone,
            shootingLocation: response.data.request.ShootingStreet__c + " " + response.data.request.ShootingCity__c,
            productName: response.data.product.Public_Product_Name__c,
            }, () => {
              this.setState({imagesInPackage: response.data.request.Number_of_Images_for_Customer__c})
              var selectedImages = []
              var selectedInThePastString = []
              var selectedInThePast = []
              if (response.data.request.Rating_Shooting__c != null) {
                this.setState({photosRate: response.data.request.Rating_Shooting__c})
              }
              if (response.data.request.Rating_Photographer__c != null) {
                this.setState({photographerRate: response.data.request.Rating_Photographer__c})
              }
              if (response.data.request.Comment_user__c != null) {
                this.setState({userComment: response.data.request.Comment_user__c})
              }
              if (response.data.request.Selected_Images__c != null) {
                selectedInThePastString = response.data.request.Selected_Images__c.replace(new RegExp('<p>', 'g'), '').replace(new RegExp('</p>', 'g'), '').replace(new RegExp('<br>', 'g'), '')
                while (selectedInThePastString.includes('1.')) {
                  selectedInThePastString = selectedInThePastString.replace('1.JPG', '(1).JPG').replace('1.png', '(1).png').replace('1.JPEG', '(1).JPEG').replace('1.jpeg', '(1).jpeg')
                }
                selectedInThePast = selectedInThePastString.split(',')
                selectedImages = selectedInThePastString.split(',')
                if (selectedImages.length == 0) {
                  this.setState({firstSelection: true})
                }
                this.setState({selected: selectedImages.length})
              }
              // pricePerExtra: response.data.product.Price_Per_Extra_Product__c
              this.setState({selectedImages: selectedImages, selectedInThePast: selectedInThePast, confirmSelect: false}, () => {
                this.getEditedImages()
              })
            })
        } else {
            this.setState({loading: false})
            alert('Shooting ID is not valid.')
        }
      } catch (err) {
          console.error(err);
      }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  async getEditedImages  ()  {
    console.log("DOWNLOADING EDITED");
    const response = await axios.get(GET_EDITED_ORGENIZED_IMAGES + this.state.requestId + '-edited',{}, {headers: {'Content-Type': 'application/json'}});
    console.log(response.data)
    var data = response.data
    var imagesForGallery = []
    for (var i = 0; i < data.watermarked.length; i++) {
      console.log(this.state.selectedInThePast);
      if (this.state.selectedInThePast.includes(data.originals[i])) {
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth:  320,
          thumbnailHeight: 174,
          isSelected: true,
          caption: "Image " + String(i)
        })
      } else {
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth:  320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: "Image " + String(i)
        })
      }
    }
    data['loading'] = false
    data['imagesForGallery'] = imagesForGallery
    this.setState({addingImagesToSalesforce: false, openConfirmDialog: false})
    this.setState({editedImages: data, addingImagesToSalesforce: false, openConfirmDialog: false})
    return ""
  }

  onChangeLogo = (e) => {
     this.setState({file: e.target.files[0], uploadingWatermark: true, updateInProgress: true});
     console.log(e.target.files[0]);
     const formData = new FormData();
      formData.append('image',e.target.files[0]);
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
      };
      // https://wesnapp.co.il/py/server/upload/
      axios.post("https://wesnapp.co.il/py/server/uploadwatermark/" + this.state.requestData.contact.AccountId,formData, config)
          .then((response) => {
            this.setState({uploadingWatermark: false}, ()=> {
              console.log("!@#!@#");
              console.log(response.data.imageUrl);

              this.setState({logo_preferences: {opacity: this.state.logo_preferences.opacity, logoSize: this.state.logo_preferences.logoSize, logoPosition: this.state.logo_preferences.logoPosition, url: response.data.imageUrl}}, () => {
                console.log(this.state.logo_preferences.url);
                this.updateLogoPreferences()
              })
              this.handleSuccess()
            });
          }).catch((error) => {
      });
  }

  updateLogoPreferences = () => {
    var formData = {data: {Logo_Preference__c: '{"url": "' + this.state.logo_preferences.url + '", "logoSize": ' + this.state.logo_preferences.logoSize + ', "logoPosition": "' + this.state.logo_preferences.logoPosition + '", "opacity": ' + this.state.logo_preferences.opacity + '}'}, accountId: this.state.requestData.contact.AccountId }
    axios.post("https://wesnapp.co.il/api/account/update-account",formData, {})
        .then((response) => {
          console.log(response.data);
          console.log(this.state.logo_preferences.url);
          this.setState({updateInProgress: false, imageHash: Date.now()})
        }).catch((error) => {
      });
  }

  confirmSelection = () => {
    var images = this.state.selectedImages
    var imagesString = ""
    var amountToCharge = 0

    if (images.length >= 1) {
      imagesString = images[0].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
      if (images.length > 1) {
        for (var i = 1; i < images.length; i++) {
          imagesString += "," + images[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
        }
      }
    }

    var paymentWithProduct = false

    this.setState({selectedImagesString: imagesString}, () => {
      if ((this.state.selectedImages.length > this.state.imagesInPackage) || this.state.requestPaid == false) {
        var imagesToPayFor = ""
        if (this.state.selectedImages.length > this.state.imagesInPackage) {
          var counter = this.state.selectedImages.length - this.state.imagesInPackage
          for (var i = 0; i < this.state.selectedImages.length; i++) {
            if (!this.state.selectedInThePast.includes(this.state.selectedImages[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).'))) {
              if (counter != 0) {
                if (imagesToPayFor == "") {
                  imagesToPayFor = this.state.selectedImages[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
                } else {
                  var imageString = this.state.selectedImages[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
                  imagesToPayFor = imagesToPayFor + "," + imageString
                }
                counter -= 1
              }
            }
          }
          amountToCharge += imagesToPayFor.split(',').length * this.state.extraImagePrice
        }
        if (this.state.requestPaid == false) {
          paymentWithProduct = true
          amountToCharge += this.state.requestData.product.Shooting_Price__c
        }
        this.setState({imagesToPayFor: imagesToPayFor, amountToCharge: amountToCharge, paymentWithProduct: paymentWithProduct }, () => {
          this.setState({openPaymentDialog: true})
        })
      } else {
        this.setState({selectedImagesString: imagesString}, () => {
          this.setState({openConfirmDialog: true})
        })
      }
    })
  }

  onSelectImage = (index) => {

    console.log("ALREADY SELECTED");
    console.log(this.state.selectedInThePast);
    console.log("@@@@@@@@@@@@@@@@@");
    var inThePast = this.state.selectedInThePast
    var inThePastSave = this.state.selectedInThePast

    var images = this.state.editedImages;
    var img = images.imagesForGallery[index];

    var selectedImages = this.state.selectedImages

    console.log(this.state.editedImages.imagesForGallery[index]);

    if (!inThePast.includes(this.removeDraft(img.src))) {
      console.log("in the past1");
      console.log(inThePastSave);
      if(img.hasOwnProperty("isSelected")) {
        if (!inThePast.includes(this.removeDraft(images.imagesForGallery[index].src))) {
          console.log("in the past2");
          console.log(inThePastSave);
          images.imagesForGallery[index].isSelected = !img.isSelected
          if (img.isSelected == true) {
            console.log("in the past3");
            console.log(inThePastSave);
            selectedImages.push(this.removeDraft(this.state.editedImages.imagesForGallery[index].src))
            console.log("in the past4");
            console.log(inThePastSave);
          } else {
            var index = selectedImages.indexOf(this.removeDraft(images.imagesForGallery[index].src));
            if (index > -1) {
              selectedImages.splice(index, 1);
            }
          }
          // console.log("selected images");
          // console.log(selectedImages);
          // console.log("in the past1");
          // console.log(this.state.selectedInThePast);
          // console.log("in the past2");
          // console.log(inThePastSave);
          if (selectedImages.length > this.state.selectedInThePast.length) {
            this.setState({selectedImages: selectedImages, confirmSelect: true, editedImages: images})
          } else {
            this.setState({selectedImages: selectedImages, confirmSelect: false, editedImages: images})
          }
        }
      }
      // this.setState({
      //     editedImages: images
      // });
    }
  }

  addImagesToSelection = () => {
    console.log("!@#!@#!@#!@#!@#!@#!@#!@#!@#!@#!@#");
    this.setState({addingImagesToSalesforce: true})
    var data = {requestId: this.state.requestId, data: {Selected_Images__c: this.state.selectedImagesString, Status__c: 'Process Completed'} }
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
      // var newSelectedImages = this.props.main.state.selectedImages
      //selectedInThePast: this.props.main.state.selectedImages,
      this.setState({openConfirmDialog: false, addingImagesToSalesforce: false}, () => {
        console.log("SELECTED IN THE PAST");
        console.log(this.state.selectedInThePast);
      })
      this.getNewSelectedImages()
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  downloadZipFileWatermark = (array, zipname) => {
    console.log(array);
    if (this.state.firstSelection) {
      this.setState({openReviewDialog: true})
    }
    if (this.state.downloadPercenteges == 0) {
      var lineOfFiles = ''
      for (var i = 0; i < array.length; i++) {
        var path = ''
        console.log(array[i]);
        path = array[i].replace('https://s3.amazonaws.com/wesnapp-development/', '')
        path.replace('/','-')
        if (lineOfFiles == '') {
          lineOfFiles = path
        } else {
          lineOfFiles = lineOfFiles + ',' + path
        }
      }
      this.setState({downloadPercenteges: 1})
      var config = {
        responseType: 'blob',
        onDownloadProgress: this.uploadDownloadProgress(),
        timeout: 2000000000000000000000000
      };
      var filesOld = lineOfFiles.split(',')
      var files = []
      var folder = filesOld[0].split("/" + filesOld[0].split('/')[filesOld[0].split('/').length - 1])[0]
      for (var j = 0; j < filesOld.length; j++) {
        files.push(filesOld[j].split(folder + '/')[1])
      }
      console.log('folder');
      console.log(folder);
      console.log(files);
      var data = {folder: folder, files: files, zipname: zipname, overlay_path: 'watermarks/' + this.state.requestData.contact.AccountId + "/overlay.png", opacity: this.state.logo_preferences.opacity, size: this.state.logo_preferences.logoSize, position: this.state.logo_preferences.logoPosition}
      console.log(data);
      axios.post('https://wesnapp.co.il/py/server/zipfiles-watermarks',data, config).then((response) => {
        console.log(response.data);
        var headers = response.headers;
         var blob = new Blob([response.data],{type:headers['content-type']});
         var link = document.createElement('a');
         link.href = window.URL.createObjectURL(blob);
         link.download = zipname;
         link.click();
         link.remove()
         this.setState({downloadPercenteges: 0})
      })
      .catch( (error) => {
        console.log(error);
      });
    }
  }


    downloadZipFile = (array, zipname) => {
      console.log(array);
      if (this.state.firstSelection) {
        this.setState({openReviewDialog: true})
      }
      if (this.state.downloadPercenteges == 0) {
        var lineOfFiles = ''
        for (var i = 0; i < array.length; i++) {
          var path = ''
          console.log(array[i]);
          path = array[i].replace('https://s3.amazonaws.com/wesnapp-development/', '')
          path.replace('/','-')
          if (lineOfFiles == '') {
            lineOfFiles = path
          } else {
            lineOfFiles = lineOfFiles + ',' + path
          }
        }
        this.setState({downloadPercenteges: 1})
        var config = {
          responseType: 'blob',
          onDownloadProgress: this.uploadDownloadProgress(),
          timeout: 2000000000000000000000000
        };
        var filesOld = lineOfFiles.split(',')
        var files = []
        var folder = filesOld[0].split("/" + filesOld[0].split('/')[filesOld[0].split('/').length - 1])[0]
        for (var j = 0; j < filesOld.length; j++) {
          files.push(filesOld[j].split(folder + '/')[1])
        }
        console.log('folder');
        console.log(folder);
        console.log(files);
        axios.post('https://wesnapp.co.il/py/server/zipfiles-images',{folder: folder, files: files, zipname: zipname}, config).then((response) => {
          console.log(response.data);
          var headers = response.headers;
         var blob = new Blob([response.data],{type:headers['content-type']});
         var link = document.createElement('a');
         link.href = window.URL.createObjectURL(blob);
         link.download = zipname;
         link.click();
         link.remove()
         this.setState({downloadPercenteges: 0})
        })
        .catch( (error) => {
          console.log(error);
        });
      }
    }

    uploadDownloadProgress = () => {
      return (progress) => {
        console.log(progress.loaded);
        console.log(progress.total);
        var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
        this.setState({downloadPercenteges: percentCompleted}, () => {})
      }
    }

  getNewSelectedImages = async () => {
    try {
      var data = {id: this.state.requestId}
      const response = await axios.post("https://wesnapp.co.il/api/request/get-full-data", data);
      console.log(response.data);
      var selectedImages = []
      var selectedInThePastString = []
      var selectedInThePast = []
      if (response.data.request.Selected_Images__c != null) {
        selectedInThePastString = response.data.request.Selected_Images__c.replace(new RegExp('<p>', 'g'), '').replace(new RegExp('</p>', 'g'), '').replace(new RegExp('<br>', 'g'), '')
        while (selectedInThePastString.includes('1.')) {
          selectedInThePastString = selectedInThePastString.replace('1.JPG', '(1).JPG').replace('1.png', '(1).png').replace('1.JPEG', '(1).JPEG').replace('1.jpeg', '(1).jpeg')
        }
        selectedInThePast = selectedInThePastString.split(',')
        selectedImages = selectedInThePastString.split(',')
        this.setState({selected: selectedImages.length})
      }
      // pricePerExtra: response.data.product.Price_Per_Extra_Product__c
      this.setState({selectedImages: selectedImages, selectedInThePast: selectedInThePast, confirmSelect: false}, () => {
        this.getEditedImages()
      })
      } catch (err) {
          console.error(err);
      }
  };

  getPreviewStyle = () => {
    var width = '15%'
    if (this.state.logo_preferences.logoSize == 66) {
      width = '20%'
    } else if (this.state.logo_preferences.logoSize == 100) {
      width = '25%'
    }
    if (this.state.logo_preferences.logoPosition == "top_left") {
      return {width: width, height: 'auto', position: 'absolute', left: 10, top: 10, opacity: this.state.logo_preferences.opacity/100}
    } else if (this.state.logo_preferences.logoPosition == "top_right") {
      return {width: width, height: 'auto', position: 'absolute', right: 10, top: 10, opacity: this.state.logo_preferences.opacity/100}
    } else if (this.state.logo_preferences.logoPosition == "bottom_left") {
      return {width: width, height: 'auto', position: 'absolute', left: 10, bottom: 10, opacity: this.state.logo_preferences.opacity/100}
    } else if (this.state.logo_preferences.logoPosition == "bottom_right") {
      return {width: width, height: 'auto', position: 'absolute', right: 10, bottom: 10, opacity: this.state.logo_preferences.opacity/100}
    }
  }

  selectAll = () => {
    var originals = this.state.editedImages
    var images = []
    for (var i = 0; i < originals.imagesForGallery.length; i++) {
      images.push(originals.imagesForGallery[i].src.replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).'))
      originals.imagesForGallery[i].isSelected = true
    }
    this.setState({selectedImages: images, editedImages: originals, selected: images.length, confirmSelect: true})
  }

  removeDraft = (url) => {
    return url.replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
  }

  handleSuccess = () => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.props.enqueueSnackbar("The company logo was successfully uploaded",
      { variant: "success",color:'white', autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
      action: key => (
                    <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
                        Dismiss
                    </Button>
            )}
      )
  }

  getStyle = (i) => {
    if (mobile) {
      return { display: 'inline-block', width: '100%',marginBottom: 10}
    } else {
      if ((i+2) % 3 == 0) {
        return { display: 'inline-block', width: '32.33%',marginBottom: 20, marginRight: '1.5%', marginLeft: '1.5%'}
      } else {
        return { display: 'inline-block', width: '32.33%',marginBottom: 20, marginRight: '0%'}
      }
    }
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root} style={{borderRadius: '25px', backgroundColor: 'whiteIcon', minHeight: '100vh', maxWidth: '100vw'}}>
        <CssBaseline />
        <Dialog
          open={this.state.openPaymentDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClosePaymentDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{this.state.paymentWithProduct ? ('Photo Shoot + Additional Media') : ('Additional media')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.paymentWithProduct ? (
                "Photo shoot not paid. Additionally you've just selected additional media. You will get charged for those. If would you like to proceed with the download, please complete the payment proccess."
              ) : (
                "You've just selected additional media. You will get charged for those. If would you like to proceed with the download, please complete the payment proccess."
              )}
            </DialogContentText>
            <div style={{marginTop: 20, width: '100%', height: 5}}></div>
            {this.state.paymentWithProduct ? (
              <div>
                {this.state.openPaymentDialog && (
                  <iframe style={{width: '100%', height: 290, marginTop: 0, frameBorder: 0, borderRadius: 0, border: 0}} src={"https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=chargeForProductAndExtra&nologo=1&sum=" + (this.state.amountToCharge * 1.17).toFixed(2) + "&price=" + (this.state.amountToCharge)	 + "&accountId=" + this.state.requestData.contact.AccountId + "&orderId=" + this.state.requestData.request.WS_Order__c + "&mobile=" + this.state.requestData.contact.MobilePhone + "&name=" + this.state.requestData.contact.Name  + "&vatType=" + "0" + "&email=" + this.state.requestData.contact.Email + "&companyNumber=" + String(this.state.requestData.product.Shooting_Price__c) + "&address=" + this.state.requestData.request.ShootingStreet__c + ", " + this.state.requestData.request.ShootingCity__c + "&city=" + this.state.requestData.request.ShootingCity__c + "&productName=" + this.state.requestData.product.Public_Product_Name__c + "&countryCode=" + "IL" + "&currencyCode=" + "ILS&contact=" + this.state.requestData.contact.AccountId + "&pdesc=" + this.state.selectedImagesString + "&remarks=" + this.state.requestData.request.Id + "&company=" + this.state.imagesToPayFor}></iframe>
                )}
              </div>
            ) : (
              <div>
                {this.state.openPaymentDialog && (
                  <iframe style={{width: '100%', height: 290, marginTop: 0, frameBorder: 0, borderRadius: 0, border: 0}} src={"https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=chargeForExtra&nologo=1&sum=" + (this.state.amountToCharge * 1.17).toFixed(2) + "&price=" + (this.state.amountToCharge)	 + "&accountId=" + this.state.requestData.contact.AccountId + "&orderId=" + this.state.requestData.request.WS_Order__c + "&mobile=" + this.state.requestData.contact.MobilePhone + "&name=" + this.state.requestData.contact.Name  + "&vatType=" + "0" + "&email=" + this.state.requestData.contact.Email + "&companyNumber=" + "" + "&address=" + this.state.requestData.request.ShootingStreet__c + ", " + this.state.requestData.request.ShootingCity__c + "&city=" + this.state.requestData.request.ShootingCity__c + "&productName=" + this.state.requestData.product.Public_Product_Name__c + "&countryCode=" + "IL" + "&currencyCode=" + "ILS&contact=" + this.state.requestData.contact.AccountId + "&pdesc=" + this.state.selectedImagesString + "&remarks=" + this.state.requestData.request.Id + "&company=" + this.state.imagesToPayFor}></iframe>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosePaymentDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openConfirmDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseConfirmDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Select Images</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <b>Note</b> - Once you select photos, you won't be able to change your selection.
            </DialogContentText>
            <div style={{marginTop: 10, width: '100%', height: 2}}></div>
            <center>
            {this.state.addingImagesToSalesforce ? (
              <div style={{marginTop: 25, height: 48}}>
                <CircularProgress size={40}/>
              </div>
            ) : (
              <Button onClick={()=> this.addImagesToSelection()} variant="contained" type="submit" elevation={0} style={{color: 'white', marginTop: 25, height: 48, borderRadius: 24, background: "linear-gradient(to right , rgb(94,44,237), #814bfa)", boxShadow: "0px 10px 20px -10px rgba(0,0,0,0.30)"}} >
                Confirm Selection
              </Button>
            )}
            </center>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfirmDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({openSnack: false})}
        >
          <MySnackbarContentWrapper
            onClose={() => this.setState({openSnack: false})}
            style={{color: 'white'}}
            variant="success"
            message="Thank you. We got your review!"
          />
        </Snackbar>
        <Dialog
          open={this.state.openReviewDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseReviewDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Write us a review!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <b>We want to hear you! Please share with us how was the photographer and what do you think about the photos.</b>
            </DialogContentText>
            <Box component="fieldset" mb={1} borderColor="transparent">
               <Typography component="legend1">Rate the photographer</Typography>
               <Rating
                 name="simple-controlled1"
                 value={this.state.photographerRate}
                 onChange={(event, newValue) => {
                   this.setRatingForPhotographer(newValue)
                 }}
               />
             </Box>
             <Box component="fieldset" mb={1} borderColor="transparent" style={{marginTop: -10}}>
                <Typography component="legend2">Rate the photos</Typography>
                <Rating
                  name="simple-controlled2"
                  value={this.state.photosRate}
                  onChange={(event, newValue) => {
                    this.setRatingForPhotos(newValue)
                  }}
                />
              </Box>
            <div style={{marginTop: -12, width: '100%', height: 2}}></div>
            <TextField
              id="outlined-multiline-static"
              label=""
              multiline
              rows="4"
              value={this.state.userComment}
              style={{width: '100%'}}
              onChange={this.handleChange('userComment')}
              margin="normal"
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            {this.state.addingComment ? (
              <div style={{marginTop: 15, height: 38}}>
                <CircularProgress size={30}/>
              </div>
            ) : (
              <Button onClick={() => this.addReview()} color="primary">
                Add Review
              </Button>
            )}
            <Button onClick={this.handleCloseReviewDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.galleryIsOpen && (
          <Lightbox
            mainSrc={this.state.editedImages.imagesForGallery[this.state.galleryIndex].src}
            nextSrc={this.state.editedImages.imagesForGallery[(this.state.galleryIndex + 1) % this.state.editedImages.imagesForGallery.length].src}
            prevSrc={this.state.editedImages.imagesForGallery[(this.state.galleryIndex + this.state.editedImages.imagesForGallery.length - 1) % this.state.editedImages.imagesForGallery.length].src}
            onCloseRequest={() => {this.setState({ galleryIsOpen: false }); this.setState({open: mobile ? false : true})}}
            onMovePrevRequest={() =>
              this.setState({
                galleryIndex: (this.state.galleryIndex + this.state.editedImages.imagesForGallery.length - 1) % this.state.editedImages.imagesForGallery.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                galleryIndex: (this.state.galleryIndex + 1) % this.state.editedImages.imagesForGallery.length,
              })
            }
          />
        )}
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: mobile ? false : this.state.open,
          })}
        >
        <SnackbarProvider maxSnack={10}>
        <Grid container spacing={24} dir="ltr" justify="center">
          <Grid item xs={12} sm={12} md={12} xl={12}>
          <div style={{paddingLeft: 0, paddingRight: 0, paddingTop: 10}}>
          {
            // <Autocomplete
            // style={{width: 'calc(70% - 30px)', textAlign: 'left', direction: 'ltr'}}
            // onPlaceSelected={(place) => {
            //   if (place.types.includes('street_address')) {
            //     console.log(true);
            //     this.setState({errorAddress: '', address: place.formatted_address})
            //   } else {
            //     console.log(false);
            //     this.setState({errorAddress: 'כתובת שגויה, בחר כתובת מדויקת המכילה מספר דירה'})
            //   }
            // }}
            // placeholder="Choose specific address"
            // className="HeaderInput"
            // style={{marginLeft: -5, display: 'inline-block', float: 'left', width: this.state.open ? ('calc(50% - 120px)') : ('calc(70% - 185px)')}}
            // types={['address']}
            //
            //
            // componentRestrictions={{country: "il"}}
            // />
          }
            {
      //     <MuiPickersUtilsProvider utils={DateFnsUtils}>
      //       <DatePicker
      //         style={{float: 'right', width: '100%'}}
      //         margin="normal"
      //         label=""
      //         value={this.state.date_from}
      //         onChange={this.handleDateChangeFrom}
      //         maxDate={new Date()}
      //         InputProps={{
      //          disableUnderline: true,
      //         }}
      //         className="HeaderInput"
      //         style={{padding: 10, display: 'inline-block', width: '20%', marginTop: 5, marginLeft: 10}}
      //       />
      //       <TimePicker
      //   showTodayButton
      //   todayLabel="now"
      //   label=""
      //   value={this.state.date_from}
      //   minutesStep={5}
      //   InputProps={{
      //    disableUnderline: true,
      //   }}
      //   onChange={this.handleDateChangeFrom}
      //   className="HeaderInput"
      //   style={{padding: 10, display: 'inline-block', width: '10%', marginTop: 5, marginLeft: 10}}
      // />
      //     </MuiPickersUtilsProvider>
          }
          {!mobile && (
            <button style={{display: 'inline-block', cursor: 'pointer', marginLeft: 0}} className="orderButton" onClick={ ()=> {this.props.history.push('/order'); }}
            style={{
              // border: '1px solid rgb(94,44,237)',
              // boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
              borderRadius: 24,
              // borderColor: 'rgb(14,209,179)',
              cursor: 'pointer',
              marginLeft: 10,
              display: 'inline-block',
              color: 'white',
              backgroundColor: '#39DFA1' ,
              height: 50,
              // border: '1px solid green'
              width: 100,
              marginTop: 5,
              float: 'left'
            }}>
              New Order
            </button>
          )}
          {!mobile && (
            <img src={saleBanner} onClick={()=> window.open('http://bit.ly/2P1UgoQ','_blank')} style={{height: 55, width: 'auto', display: 'inline-block', cursor: 'pointer'}} />
          )}
          {!mobile && (
            <div style={{ backgroundColor: 'rgb(239,242,249)', height: 50, width: 50, fontWeight: 'bold', paddingLeft: 10, fontSize: 15, paddingRight: 10, display: this.state.open ? ('none') : ('inline-block'), borderRadius: 25, marginTop: 5, verticalAlign: 'top', marginLeft: 15, float: 'right'}}>
              <center>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={this.handleDrawerOpen}
                style={{marginLeft: -12, marginTop: 2, height: 50}}
                className={clsx(this.state.open && classes.hide)}
              >
                <MenuIcon style={{color: 'rgb(129,139,166)'}} />
              </IconButton>
              </center>
            </div>
          )}
          {!mobile && (
            <div style={{color: 'rgb(129,139,166)', fontFamily: 'arial', backgroundColor: 'rgb(239,242,249)', height: 50, width: 50, fontWeight: 'bold', fontSize: 22, display: 'inline-block', borderRadius: 25, paddingTop: 10, marginTop: 5, verticalAlign: 'top', marginLeft: 15, float: 'right'}}>
              <center>
                {this.state.avatar}
              </center>
            </div>
          )}
          {
          // <button style={{display: 'inline-block', cursor: 'pointer', marginTop: 5, marginLeft: 10}} className="orderButton" onClick={ ()=> console.log()}
          // style={{
          //   border: '1px solid rgb(94,44,237)',
          //   boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
          //   borderRadius: 25,
          //   borderColor: 'rgb(94,44,237)',
          //   cursor: 'pointer',
          //   marginLeft: 10,
          //   display: 'inline-block',
          //   color: 'rgb(94,44,237)',
          //   backgroundColor: 'white' ,
          //   height: 50,
          //   width: 50,
          //   border: '1px solid rgb(94,44,237)',
          //   float: 'right'
          // }}>
          //   <Notifications/>
          // </button>
          // <button style={{display: 'inline-block', cursor: 'pointer', marginTop: 5, marginLeft: 10}} className="orderButton" onClick={ ()=> console.log()}
          // style={{
          //   border: '1px solid rgb(94,44,237)',
          //   boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
          //   borderRadius: 25,
          //   borderColor: 'rgb(94,44,237)',
          //   cursor: 'pointer',
          //   marginLeft: 10,
          //   display: 'inline-block',
          //   color: 'rgb(94,44,237)',
          //   backgroundColor: 'white' ,
          //   height: 50,
          //   width: 50,
          //   border: '1px solid rgb(94,44,237)',
          //   float: 'right'
          // }}>
          //   <Notifications/>
          // </button>
        }
        {
          mobile ? (
            <div>
              <div style={{position: 'absolute', top: 4, right: 15, zIndex: 0}}>
                <IconButton
                  color="white"
                  aria-label="open drawer"
                  edge="end"
                  onClick={this.handleDrawerOpen}
                  style={{height: 50, color: 'white'}}
                  className={clsx(this.state.open && classes.hide)}
                >
                  <MenuIcon style={{color: 'white'}} />
                </IconButton>
              </div>
            </div>
          ) : (
            <div>
            </div>
          )
        }
            <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: mobile ? 0 : 20, marginBottom: mobile ? 0 : 30}}>
              <b>Photo Shoot</b> #{this.state.requestName}
            </div>
            <div style={{marginTop: 0}}>
            {
              // <div style={{marginRight: 30, display: 'inline-block', verticalAlign: 'top', textAlign: 'left', backgroundColor: 'rgb(246,247,250)', width: 'calc(70% - 30px)',  height: 200, borderRadius: 30}}>
              // </div>
            }
            {
              !mobile && (
                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top', borderRadius: 30, height: 200, position: 'relative'}}>
                <Map google={window.google} lat={lat} lng={lng}/>
                </div>
              )
            }
            </div>
            <div style={{marginTop: mobile ? 15 : 30, textAlign: 'left'}}></div>
            {mobile ? (
              <div style={{color: 'rgb(129,139,166)',  width: mobile ? '100vw' : '100%', position: mobile ? 'absolute' : 'static', left: 0, overflow: 'auto', whiteSpace: 'nowrap'}}>
              <div style={{fontFamily: 'arial', display: 'inline-block', marginRight: mobile ? 10 : '2.66%', marginLeft: mobile ? 10 : 0, width: mobile ? '80%' : '23%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                  <PersonIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                </div>
                <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                  <div style={{marginTop: 4, maxWidth: '80%'}}><b>Photographer</b></div>
                  <div style={{marginTop: 8}}>
                    {this.state.photographerName}
                  </div>
                </div>
              </div>
              <div style={{fontFamily: 'arial', display: 'inline-block', marginRight: mobile ? 10 : '2.66%', width: mobile ? '80%' : '23%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                  <PhotoCameraIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                </div>
                <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                  <div style={{marginTop: 4}}><b>Product</b></div>
                  <div style={{marginTop: 8}}>
                    {this.state.productName}
                  </div>
                </div>
              </div>
              <div style={{fontFamily: 'arial', display: 'inline-block', marginRight: mobile ? 10 : '2.66%', width: mobile ? '80%' : '23%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                  <LocationCityIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                </div>
                <div style={{display: 'inline-block', textAlign: 'left', maxWidth: 'calc(100% - 85px)', marginLeft: 10, height: 50, marginTop: 20, float: 'left', borderRadius: 15, fontSize: 16}}>
                  <div style={{marginTop: 4}}><b>Location</b></div>
                  <div style={{marginTop: 8}}>
                    {this.state.shootingLocation}
                  </div>
                </div>
              </div>
              <div style={{fontFamily: 'arial', display: 'inline-block', marginRight: mobile ? 10 : '0', width: mobile ? '80%' : '23%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                  <CalendarTodayIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                </div>
                <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                  <div style={{marginTop: 4}}><b>Date & Time</b></div>
                  <div style={{marginTop: 8}}>
                    {this.state.dateAndTime}
                  </div>
                </div>
              </div>
            </div>
            ) : (
              <div style={{color: 'rgb(129,139,166)', width: '100%'}}>
                <Grid container spacing={2} dir="ltr" justify="center">
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <PersonIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4, maxWidth: '80%'}}><b>Photographer</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.photographerName}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <PhotoCameraIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4}}><b>Product</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.productName}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <LocationCityIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', maxWidth: 'calc(100% - 85px)', marginLeft: 10, height: 50, marginTop: 20, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4}}><b>Location</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.shootingLocation}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <CalendarTodayIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4}}><b>Date & Time</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.dateAndTime}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          <div style={{width: '100%', marginBottom: 0}}>
          <div style={{width: '100%', marginBottom: 0, height: mobile ? 120 : 0}}>
          </div>
          {(this.state.downloadPercenteges != 100 && this.state.downloadPercenteges != 0) && (
            <div style={{width: '100%', marginTop: 10, textAlign: 'left', marginBottom: 10, height: 40}}>
              <div style={{marginTop: 0}}>
                <div style={{width: '100%', textAlign: 'left', color: 'black', marginBottom: 10}}>Download in progress - {this.state.downloadPercenteges}%</div>
                <BorderLinearProgress variant="determinate" value={this.state.downloadPercenteges} />
              </div>
            </div>
          )}
            <div style={{display: 'inline-block', float: 'left', fontSize: 24, color: 'black', color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: mobile ? 15 : 34, marginBottom: mobile ? 15 : 30}}>
              <b>Choose</b> Photos
            </div>
            <div style={{marginBottom: mobile ? 15 : 0, marginTop: mobile ? 0 : 30, overflow: 'auto', whiteSpace: 'nowrap', maxWidth: 'calc(100vw - 50px)', float: mobile ? 'left' : 'right'}}>
            {this.state.selectedInThePast.length > 0 && (
              <div style={{display: 'inline-block',fontWeight: 'bold', float: mobile ? 'center' : 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginBottom: 0, marginTop: mobile ? 0 : 0}}>
                <Tooltip title={"Download Zip"} placement="top">
                  <Button onClick={() => this.downloadZipFile(this.state.selectedInThePast, 'WeSnapp_' + this.state.requestName + '_edited.zip')} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, backgroundColor: "rgb(0,230,18)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}}
                  style={{
                    border: '1px solid rgb(94,44,237)',
                    boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
                    borderRadius: 20,
                    borderColor: 'rgb(94,44,237)',
                    color: 'rgb(94,44,237)',
                    backgroundColor: 'white' ,
                    cursor: 'pointer',
                    height: 40,
                    border: '1px solid rgb(94,44,237)'
                  }}>
                    <CloudDownload style={{marginRight: 8}}/> Download
                  </Button>
                </Tooltip>
              </div>
            )}
            {this.state.logo_preferences.url != "" && (
              <div style={{display: 'inline-block',fontWeight: 'bold', marginRight: 15, marginLeft: 15, float: mobile ? 'center' : 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginBottom: 0, marginTop: mobile ? 0 : 0}}>
              {this.state.selectedInThePast.length > 0 && (
                <Tooltip title={"Download Zip"} placement="top">
                  <Button onClick={() => this.downloadZipFileWatermark(this.state.selectedInThePast, 'WeSnapp_' + this.state.requestName + '_edited.zip')} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, backgroundColor: "rgb(0,230,18)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}}
                  style={{
                    border: '1px solid rgb(94,44,237)',
                    boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
                    borderRadius: 20,
                    borderColor: 'rgb(94,44,237)',
                    color: 'rgb(94,44,237)',
                    backgroundColor: 'white' ,
                    cursor: 'pointer',
                    height: 40,
                    border: '1px solid rgb(94,44,237)'
                  }}>
                    <CloudDownload style={{marginRight: 8}}/> Download Watermarked
                  </Button>
                </Tooltip>
              )}
              </div>
            )}
              {(this.state.selectedInThePast.length != this.state.editedImages.imagesForGallery.length && this.state.selectedImages.length != this.state.editedImages.imagesForGallery.length) && (
                <div style={{display: 'inline-block',fontWeight: 'bold', float: mobile ? 'center' : 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginBottom: 0, marginTop: mobile ? 0 : 0, marginRight: 15, width: mobile ? 'auto%' : 'auto'}}>
                  <Button onClick={()=> this.selectAll()} variant="contained"
                  style={{
                    border: '1px solid rgb(94,44,237)',
                    boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
                    borderRadius: 20,
                    borderColor: 'rgb(94,44,237)',
                    cursor: 'pointer',
                    color: 'rgb(94,44,237)',
                    backgroundColor: 'white' ,
                    height: 40,
                    border: '1px solid rgb(94,44,237)'
                  }}>
                    Select All
                  </Button>
                </div>
              )}
            </div>
            </div>
            {this.state.editedImages.imagesForGallery.length > 0 && (
              <div>
              <div style={{display: 'inline-block', width: 'calc(100% - 0px)', verticalAlign:'top'}}>
                {this.state.editedImages.imagesForGallery.map((item, i) => (
                  <div style={this.props.main.state.deviceType == "desktop" ? this.getStyle(i) : {display: 'inline-block', width: '100%',marginBottom: 10}}  >
                    <div className="imgcontainer">
                      <img src={item.src} alt="Avatar" className="image" style={{width: '100%'}} />
                      <div className="middle">
                        <img src={zoomInIcon} alt="Avatar" className="text" width="80px" height="80px" onClick={() => {this.setState({galleryIsOpen: true, galleryIndex: i}); this.setState({open: false})}}/>
                      </div>
                      <div className="boxinimage">
                        <div style={{height: 75}}>
                          <div style={{display: 'inline-block', fontSize: '19px', marginTop: 28, float: 'left', marginLeft: 12}}>Photo #{i+1}</div>
                          {this.state.selectedInThePast.includes(item.src.replace('_reduced_watermarked', '').replace('watermarked/','').replace('1.', '(1).')) ? (
                            <Tooltip title={"Selected"} placement="top">
                              <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: 24, color: 'white', float: 'right', border: 'none', outline: 'none',background: 'transparent'}} onClick={ ()=> this.onSelectImage(i)}>
                                <FavoriteIcon style={{width: 25, height: 25, color: 'rgb(14,209,179)'}}/>
                              </button>
                            </Tooltip>
                          ) : (
                            <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: 24, color: 'white', float: 'right', border: 'none', outline: 'none', background: 'transparent', marginRight: 12}} onClick={ ()=> this.onSelectImage(i)}>
                            { item.isSelected && (<FavoriteIcon style={{width: 25, height: 25, color: '#39DFA1'}}/>)}
                            { !item.isSelected && (<FavoriteBorderIcon style={{width: 25, height: 25, color: '#39DFA1'}}/>)}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {
                    // <div className="bar">
                    //   <div style={{display: 'inline-block', fontSize: '19px', marginTop: -3}}>Photo #{i+1}</div>
                    //   {this.state.selectedInThePast.includes(item.src.replace('_reduced_watermarked', '').replace('watermarked/','').replace('1.', '(1).')) ? (
                    //     <Tooltip title={"Selected"} placement="top">
                    //       <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -8, color: 'white', float: 'right', border: 'none', outline: 'none',background: 'transparent'}} onClick={ ()=> this.onSelectImage(i)}>
                    //         <img src={confirmedIcon} width="25" height="25" style={{background: 'transparent'}}/>
                    //       </button>
                    //     </Tooltip>
                    //   ) : (
                    //     <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -8, color: 'white', float: 'right', border: 'none', outline: 'none', background: 'transparent'}} onClick={ ()=> this.onSelectImage(i)}>
                    //     { item.isSelected && (<img src={selectedIcon} width="25" height="25"/>)}
                    //     { !item.isSelected && (<img src={unselectedIcon} width="25" height="25"/>)}
                    //     </button>
                    //   )}
                    // </div>
                  }
                  </div>
                ))}
                </div>
              </div>
            )}
            {
            // <div style={{width: 300, height: 280, backgroundColor: 'gray', borderRadius: 20}}>
            //   <div style={{width: '100%', height: 221, borderRadius: 20}}>
            //   </div>
            //   <div style={{width: '100%', height: 60, borderRadius: 20, backgroundColor: 'rgb(246,247,250)'}}>
            //   </div>
            // </div>
            }
          </div>
          <div className={classes.drawerHeader} />
          </Grid>
          </Grid>
          </SnackbarProvider>
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor= {mobile ? "bottom" : "right"}
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            {mobile ? (
              <IconButton onClick={this.handleDrawerClose} style={{marginTop: 38}}>
                <ArrowDown />
              </IconButton>
            ) : (
              <IconButton onClick={this.handleDrawerClose} style={{marginTop: 38}}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            )}
          </div>
          <div>
          {
            //
          }
          <div style={{paddingLeft: 30, paddingRight: 30, color: 'rgb(129,139,166)', fontFamily: 'arial'}}>
          <Paper square style={{backgroundColor: 'rgb(238,242,249)'}} elevation={0}>
           <Tabs
             value={this.state.sideMenuTab}
             indicatorColor="primary"
             textColor="primary"
             onChange={this.handleChangeSideMenuTab}
             aria-label="disabled tabs example"
             style={{backgroundColor: 'rgb(238,242,249)'}}
           >
             <Tab label="Selection" />
             <Tab label="Logo" />
           </Tabs>
         </Paper>
         {this.state.sideMenuTab == 0 && (
           <div>
             <div style={{display: 'inline-block',float: 'left', fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Payment Status</div>
             <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.requestData != null && (<div>{this.state.requestPaid ? (<span style={{color: '#39DFA1'}}>Paid</span>) : (<span  style={{color: 'red'}}>Not Paid</span>)}</div>)}</div>
             <div style={{height: 50, width: '100%'}}></div>
             <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
             <div style={{display: 'inline-block',float: 'left', fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Images in pack</div>
             <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.extraImagePrice != 0 && (<div>{this.state.imagesInPackage}</div>)}</div>
             <div style={{height: 50, width: '100%'}}></div>
             <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
             <div style={{display: 'inline-block',float: 'left', fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Extra photo price</div>
             <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.extraImagePrice != 0 && (<div>{this.state.requestData.product.Price_Per_Extra_Product__c} ILS</div>)}</div>
             <div style={{height: 50, width: '100%'}}></div>
             {this.state.extraImageDiscount != null && (
               <div style={{width: '100%'}}>
                <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                <div style={{display: 'inline-block',float: 'left', fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Discount</div>
                <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.extraImagePrice != 0 && (<div>{this.state.extraImageDiscount}%</div>)}</div>
               </div>
             )}
             <div style={{height: 50, width: '100%'}}></div>
             <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, color: '#333', textAlign: 'left', marginBottom: 20, marginTop: 16, color: 'rgb(40,40,53)'}}>My Selection</div>
             <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
             <div>
               <div style={{display: 'inline-block',float: 'left', fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Selected Images</div>
               <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.selectedImages.length > this.state.imagesInPackage ? this.state.imagesInPackage : this.state.selectedImages.length } x 0 ILS</div>
               <div style={{height: 50, width: '100%'}}></div>
             </div>
             <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
             {this.state.selectedImages.length > this.state.imagesInPackage && (
               <div>
                 <div>
                 <div style={{display: 'inline-block',float: 'left',  fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Extra Images</div>
                  <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{(this.state.selectedImages.length - this.state.imagesInPackage)} x {this.state.requestData.product.Price_Per_Extra_Product__c} ILS</div>
                  <div style={{height: 50, width: '100%'}}></div>
                 </div>
                 {this.state.extraImageDiscount != null && (
                   <div>
                    <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                     <div style={{display: 'inline-block',float: 'left',  fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Discount</div>
                     <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>-{((this.state.selectedImages.length - this.state.imagesInPackage) * (this.state.requestData.product.Price_Per_Extra_Product__c - this.state.extraImagePrice)).toFixed(2)} ILS</div>
                     <div style={{height: 50, width: '100%'}}></div>
                   </div>
                 )}
                </div>
              )}
              {this.state.requestData != null && (
                <div>
                 {this.state.requestData.request.Paid__c == false && (
                   <div>
                    <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                    <div style={{display: 'inline-block',float: 'left',  fontFamily: 'arial', fontSize: 16, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>{this.state.requestData.product.Public_Product_Name__c}</div>
                    <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, fontWeight: 'bold'}}>{this.state.requestData.product.Shooting_Price__c} ILS</div>
                    <div style={{height: 50, width: '100%'}}></div>
                   </div>
                  )}
                </div>
              )}
              {(this.state.selectedImages.length > this.state.imagesInPackage) || (this.state.requestPaid == false) ? (
                <div>
                 <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
                 <div style={{display: 'inline-block',float: 'left', fontWeight: 'bold', fontFamily: 'arial', fontSize: 16, color: '#333', textAlign: 'left', marginBottom: 0, marginTop: 16, color: 'rgb(40,40,53)'}}>Order Totals</div>
                 {this.state.requestPaid == true ? (
                   <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, color: 'rgb(94,44,237)', fontWeight: 'bold'}}>{((this.state.selectedImages.length - this.state.imagesInPackage) * this.state.extraImagePrice).toFixed(2)} ILS + VAT</div>
                 ) : (
                   <div>
                    {this.state.selectedImages.length > this.state.imagesInPackage ? (
                      <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, color: 'rgb(94,44,237)', fontWeight: 'bold'}}>{((this.state.selectedImages.length - this.state.imagesInPackage) * this.state.extraImagePrice + this.state.requestData.product.Shooting_Price__c).toFixed(2)} ILS + VAT</div>
                    ) : (
                      <div style={{display: 'inline-block', float: 'right', marginTop: 16, fontSize: 16, color: 'rgb(94,44,237)', fontWeight: 'bold'}}>{(this.state.requestData.product.Shooting_Price__c).toFixed(2)} ILS + VAT</div>
                    )}
                   </div>
                 )}
                 <div style={{height: 50, width: '100%'}}></div>
               </div>
             ) : (<div></div>)}
             <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>
             <div style={{width: '100%', marginBottom: 10, fontFamily: 'arial', fontWeight: 'thin'}}>
              <div style={{marginTop: 15}}>
                <div style={{width: '100%'}}>
                <div style={{height: 0, width: '100%'}}></div>
                 {this.state.confirmSelect && (
                   <div>
                     <div style={{textAlign: 'left'}}>
                       <Button onClick={()=> this.confirmSelection()} variant="contained" type="submit" elevation={0} style={{color: 'white', marginTop: 10, marginBottom: 5, height: 48, borderRadius: 24, background: "linear-gradient(to right , rgb(94,44,237), rgb(94,44,237))", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} className = "flash-button" >
                         Confirm Selection
                       </Button>
                     </div>
                     <div style={{height: 20, width: '100%'}}></div>
                   </div>
                 )}
                 </div>
               </div>
             </div>
           </div>
         )}
         {this.state.sideMenuTab == 1 && (
           <div>
              {mobile ? (<div style={{fontWeight: 'bold', width: '100%', fontSize: 18, fontFamily: 'arial', textAlign: 'center', marginTop: 50}}>Oops.. <br/><br/>Available only from desktop</div>) : (


             <div>
              {
               // <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, color: '#333', textAlign: 'left', marginBottom: 20, marginTop: 25, color: 'rgb(40,40,53)'}}>Insert Your Logo</div>
               // <Divider style={{color: 'rgb(226,225,240)', backgroundColor: 'rgb(226,225,240)'}}/>

             }
                <div style={{ fontFamily: 'arial', fontSize: 18, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 15, marginTop: 15, color: 'rgb(40,40,53)'}}>Upload your logo</div>
                <form onSubmit={this.onFormSubmit}>
                {
                  //<img src={this.state.logoUrl} alt="Avatar" style={{width: 30, height: 'auto', display: 'inline-block'}}/>
                }
                  <input type="file" name="myImage" onChange= {this.onChangeLogo} style={{display: 'inline-block', textAlign: 'left', width: '100%'}}/>
                  {this.state.uploadingWatermark && (
                    <div style={{marginTop: 0, display: 'inline-block'}}>
                      <CircularProgress size={20} style={{marginTop: 0}}/>
                    </div>
                  )}
                </form>
                <div style={{ fontFamily: 'arial', fontSize: 18, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 15, marginTop: 15, width: '100%', color: 'rgb(40,40,53)'}}>Choose position</div>
                <div>
                <RadioGroup aria-label="gender" name="gender2" value={this.state.logo_preferences.logoPosition} onChange={this.handleChangeLogoPosition}>
                  <FormControlLabel
                    value="top_right"
                    control={<Radio color="primary" />}
                    label="Top Right"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="bottom_right"
                    control={<Radio color="primary" />}
                    label="Bottom Right"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="top_left"
                    control={<Radio color="primary" />}
                    label="Top Left"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="bottom_left"
                    control={<Radio color="primary" />}
                    label="Bottom Left"
                    labelPlacement="end"
                  />
                </RadioGroup>
                </div>
                <div style={{fontFamily: 'arial', fontSize: 18, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 15, marginTop: 15, color: 'rgb(40,40,53)'}}>Choose opacity</div>
                  <div style={{marginTop: 25}}>
                    <Slider
                      value={this.state.logo_preferences.opacity}
                      defaultValue={this.state.logo_preferences.opacity}
                      getAriaValueText={() => this.handleChangeOpacity}
                      aria-labelledby="discrete-slider-always"
                      onChange={this.handleChangeOpacity}
                      step={10}
                      marks={[]}
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div style={{fontFamily: 'arial', fontSize: 18, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 15, marginTop: 15, color: 'rgb(40,40,53)'}}>Choose size</div>
                   <Slider
                    value={this.state.logo_preferences.logoSize}
                    defaultValue={this.state.logo_preferences.logoSize}
                     aria-labelledby="discrete-slider-restrict"
                     step={null}
                     onChange={this.handleChangeSize}
                     valueLabelDisplay="auto"
                     marks={[{value: 33, label: 'small'},{value: 66, label: 'medium'},{value: 100, label: 'large'}]}
                   />
                <div style={{fontFamily: 'arial', fontSize: 18, color: '#333', fontWeight: 'bold', textAlign: 'left', marginBottom: 20, marginTop: 25, color: 'rgb(40,40,53)'}}>Preview</div>
                {this.state.editedImages.imagesForGallery.length > 0 && (
                  <div style={{ position: 'relative', textAlign: 'center', color: 'white'}}>
                    <img src={this.state.editedImages.imagesForGallery[0].src} alt="Avatar" className="text" width="80px" height="80px" style={{width: '100%', height: 'auto', borderRadius: 8}}/>
                    {(this.state.logo_preferences.url != "" && this.state.updateInProgress == false) ? (
                      <img src={`${this.state.logo_preferences.url}?${this.state.imageHash}`} alt="Avatar" className="text" width="80px" height="80px" style={this.getPreviewStyle()}/>
                    ) : (
                      <div>
                        {this.state.updateInProgress == true ? (<div></div>) : (
                          <img src={black_logo} alt="Avatar" className="text" width="80px" height="80px" style={this.getPreviewStyle()}/>
                        )}
                      </div>
                    )}
                  </div>
                )}
             </div>
                   )}
           </div>
         )}
            </div>
          </div>
        </Drawer>
        {(mobile && this.state.confirmSelect) && (
        <Button onClick={()=> this.confirmSelection()} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, backgroundColor: "rgb(0,230,18)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}}
        style={{
          // border: '1px solid rgb(94,44,237)',
          boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
          borderRadius: 20,
          borderColor: 'rgb(94,44,237)',
          color: 'rgb(94,44,237)',
          backgroundColor: 'rgb(14,209,179)' ,
          cursor: 'pointer',
          color: 'white',
          height: 40,
          // border: '1px solid rgb(94,44,237)',
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 0
        }}>
          Confirm selection
        </Button>
      )}
      </div>
    );
  }
}

// ShootingPage.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };
//
// export default withStyles(styles, { withTheme: true })(ShootingPage);

ShootingPage.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
    withSnackbar(ShootingPage),
);
