import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import SideMenu from './SideMenu';
import View from './View';
import SmsDialog from './SmsDialog';
import OrderStepper from './OrderStepper';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgb(127,100,246)',
    backgroundColor: 'white'
  }
});

class Template extends React.Component {

  state = {
    lat: 32,
    lng: 32,
    selectedProduct: '-1',
    selectedProductData: null,
    smsDialog: false,
    willBeOnSite: true,
    errorFirstName: false,
    errorLastName: false,
    errorPhoneNumber: false,
    errorAddress: false,
    errorDate: false,
    errorCode: false,
    errorTime: false,
    errorOfficeName: false,
    errorWillBeOnSiteName: false,
    errorWillBeOnSitePhone: false,
    errorSelectProduct: false,
    errorEmail: false,

    formatedDate: null,
    formatedTime: null,

    isLoading: false,

    reference: '',

    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    officeName: '',
    code: '',
    inputCode: '',

    formattedAddress: '',
    whoWillBeOnSiteName: '',
    whoWillBeOnSitePhone: '',
    date: '',
    time: '',

    newUser: false,

    countryCode: '972',

    activeStep: 0,

    openPackages: [],
    productsFromPackages: [],
    searchForOpenPackages: true,
    products: [],
    isPackage: false,
    selectedPackage: null
  }

   setUrlParameter = (url, key, value) =>  {
    var key = encodeURIComponent(key),
        value = encodeURIComponent(value);

    var baseUrl = url.split('?')[0],
        newParam = key + '=' + value,
        params = '?' + newParam;

    var urlQueryString = ''

    if (url.split('?')[1] === undefined){ // if there are no query strings, make urlQueryString empty
        urlQueryString = '';
    } else {
        urlQueryString = '?' + url.split('?')[1];
    }

    // If the "search" string exists, then build params from it
    if (!urlQueryString) {
        var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
        var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

        if (value === undefined || value === null || value === '') { // Remove param if value is empty
            params = urlQueryString.replace(removeRegex, "$1");
            params = params.replace(/[&;]$/, "");

        } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
            params = urlQueryString.replace(updateRegex, "$1" + newParam);

        } else if (urlQueryString == '') { // If there are no query strings
            params = '?' + newParam;
        } else { // Otherwise, add it to end of query string
            params = urlQueryString + '&' + newParam;
        }
    }

    // no parameter was set so we don't need the question mark
    params = params === '?' ? '' : params;

    return baseUrl + params;
}

  validateMobile = (phone, source) => {
    console.log(phone);
    var substring1 = " 9725"
    var substring2 = " 97205"
    if (phone.includes(substring1) || phone.includes(substring2)) {
      console.log("@@");
      if (phone.indexOf(substring1) !== -1) {
        console.log(phone);
        phone = phone.replace(" 9725", "05");
        phone = phone.replace("-", "");
        if (phone[0] == "0" && phone[1] == "5" && ((phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3"))) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({[source]: phone})
            return true
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(" 97205", "05");
        phone = phone.replace("-", "");
        console.log(phone);
        if (phone[0] == "0" && phone[1] == "5" && ((phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3"))) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({[source]: phone})
            return true
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace("-", "");
      if (phone[0] == "0" && phone[1] == "5" && ((phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8" || phone[2] == "3"))) {
        if (phone.length == 10 && (/^\d+$/.test(phone))) {
          this.setState({[source]: phone})
          return true
        }
      } else {
        return false;
      }
    }
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  resetErrors = () => {
    this.setState({
      errorFirstName: false,
      errorLastName: false,
      errorPhoneNumber: false,
      errorAddress: false,
      errorDate: false,
      errorCode: false,
      errorOfficeName: false,
      errorTime: false,
      errorWillBeOnSiteName: false,
      errorWillBeOnSitePhone: false,
      errorSelectProduct: false,
      errorEmail: false,
      admin: true
    })
  }

  nextStep = () => {
    this.resetErrors()
    var step = this.state.activeStep
    if (step == 0) {
      var allowToContinue = true
      if (this.state.firstName.length <= 1) {
        this.setState({errorFirstName: true})
        allowToContinue = false
      }
      if (this.state.lastName.length <= 1) {
        this.setState({errorLastName: true})
        allowToContinue = false
      }
      if (!this.validateEmail(this.state.email)) {
        this.setState({errorEmail: true})
        allowToContinue = false
      }
      if (!this.validateMobile(this.state.phone, 'phone')) {
        this.setState({errorPhoneNumber: true})
        allowToContinue = false
      } else {
        this.checkIfNewUser()
      }
      if (allowToContinue) {
        this.continueStepOne()
      }
    } else if (step == 1) {
      var allowToContinue = true
      if (!this.state.willBeOnSite) {
        if (this.state.whoWillBeOnSiteName.length <= 1) {
          this.setState({errorWillBeOnSiteName: true})
          allowToContinue = false
        }
        if (!this.validateMobile(this.state.whoWillBeOnSitePhone, 'whoWillBeOnSitePhone')) {
          this.setState({errorWillBeOnSitePhone: true})
          allowToContinue = false
        }
      }
      if (this.state.date == '') {
        this.setState({errorDate: true})
        allowToContinue = false
      }
      if (this.state.time == '') {
        this.setState({errorTime: true})
        allowToContinue = false
      }
      if (this.state.formattedAddress == '') {
        this.setState({errorAddress: true})
        allowToContinue = false
      }
      if (this.state.selectedProduct == '-1') {
        this.setState({errorSelectProduct: true})
        allowToContinue = false
      }
      if (allowToContinue) {
        this.continueStepTwo()
      }
    }
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  addParameter = (key, value) => {
    var currentUrl = window.location.href;
    var paramsInUrl = currentUrl.split('&');
    var flag = true;
    var stop = true
    for(var i in paramsInUrl)
    {
        if(!paramsInUrl[i].includes(key + '=')) {
            continue;
        }
        else
        {
            var cur_value = this.getParameterByName(key)
            if (String(cur_value) != String(value)) {
              currentUrl = currentUrl.replace(key + '=' + cur_value, key + '=' + value)
              console.log(currentUrl);
              flag = false;
              break;

            } else {
              flag = false;
              stop = false
              break
            }
        }
    }
    if (flag)
    {
        currentUrl += ('&' + key + '=' + value)
        // console.log(Link(currentUrl).href)
        // if (history.pushState) {
        //   window.history.pushState("object or string", "Title", "/new-url");
        // } else {
        //   document.location.href = "/new-url";
        // }
        window.history.pushState('','', currentUrl)
        // window.location = currentUrl;
    } else {
      if (stop) {
        window.history.pushState('','', currentUrl)

      }
    }
  }

  componentDidMount() {

    var products = this.getParameterByName('products')
    if (products != null && products != undefined && products != "") {
      console.log(products.split('-'));
      this.getProducts(products.split('-'))
    }

    var reference = this.getParameterByName('reference')
    if (reference != null && reference != undefined && reference != "") {
      this.setState({reference: reference})
    }

    var admin = this.getParameterByName('admin')
    if (admin != null && admin != undefined && admin != "") {
      if (admin == "wesnapp2020!") {
        this.setState({admin: true})
      }
    }

    var firstName = this.getParameterByName('firstName')
    if (firstName != null && firstName != undefined && firstName != "") {
      this.setState({firstName: firstName})
    }

    var lastName = this.getParameterByName('lastName')
    if (lastName != null && lastName != undefined && lastName != "") {
      this.setState({lastName: lastName})
    }

    var phone = this.getParameterByName('mobile')
    if (phone != null && phone != undefined && phone != "") {
      this.setState({phone: phone})
    }

    var email = this.getParameterByName('email')
    if (email != null && email != undefined && email != "") {
      this.setState({email: email})
    }

    var officeName = this.getParameterByName('officeName')
    if (officeName != null && officeName != undefined && officeName != "") {
      this.setState({officeName: officeName})
    }

    this.addParameter('page', '1')
  }

  getProducts = (products) => {
    console.log("!@#");
    var data = {
      "products": products,
    }
    axios.post('https://wesnapp.co.il' + '/api/product/get-many',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
        if (response.data.length > 0) {
          this.setState({products: response.data})
        } else {
          // this.setState({newUser: true})
        }
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  loading = (flag) => {
    this.setState({isLoading: flag})
  }

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000)
    return code
  }

  checkIfNewUser = () => {
    var data = {
      "phone": this.state.phone,
    }
    axios.post('https://wesnapp.co.il' + '/api/contact/find-user-by-phone-number',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
        if (response.data.length > 0) {
          this.setState({newUser: false})
          this.checkForOpenPackages(response.data[0].AccountId)
        } else {
          this.setState({newUser: true})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  checkForOpenPackages = (clientId) => {
    var data = {
      "id": clientId,
    }
    axios.post('https://wesnapp.co.il' + '/api/order/get-open-orders',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
      var products = []
      for (var i = 0; i < response.data.length; i++) {
        products.push(response.data[i].singleProductId)
      }
      this.setState({openPackages: response.data})
      this.getProductsFromPackages(products, response.data)
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getProductsFromPackages = (products, packages) => {
    console.log("!@#");
    var data = {
      "products": products,
    }
    axios.post('https://wesnapp.co.il' + '/api/product/get-many',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          this.setState({productsFromPackages: response.data, searchForOpenPackages: false})
        } else {
          this.setState({searchForOpenPackages: false})
        }

    })
    .catch( (error) => {
      console.log(error);
    });
  }

  sendVerificationCode = () => {
    var data = {
      "phone": ("+" + this.state.countryCode + this.state.phone),
      "text": 'WeSnapp verification code: ' + this.state.code
    }
    axios.post('https://wesnapp.co.il' + '/api/sms/send-message',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response);
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  findClientUsingPhoneNumber = () => {
    var data = {
      "phone": this.state.phone,
    }
    axios.post('https://wesnapp.co.il' + '/api/account/find-account-by-phone-number',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response);
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  verifyCode = () => {
    this.setState({errorCode: false})
    if (this.state.code == this.state.inputCode) {
      this.setState({smsDialog: false, activeStep: 1})
      this.addParameter('page', '2')
    } else {
      this.setState({errorCode: true})
    }
  }

  continueStepOne = () => {
    if (this.state.admin) {
      this.setState({activeStep: 1})
      this.addParameter('page', '2')
    } else {
      this.setState({smsDialog: true})
      this.setState({code: this.generateRandomCode()}, () => {
        this.sendVerificationCode()
      })
    }
  }

  continueStepTwo = () => {
    this.createRequest()
  }

  createRequest = () => {
    this.loading(true)
    var address = this.state.formattedAddress.split(", ")
    var street = address[0]
    var city = address[1]
    var country = address[2]
    console.log(city);
    console.log(country);
    console.log(street);
    var officeName = this.state.officeName
    if (this.state.officeName == '') {
      officeName = this.state.firstName + " " + this.state.lastName
    }
    var data = {
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "mobile": this.state.phone,
      "email": this.state.email,
      "selectedProduct": this.state.selectedProduct,
      "reference": this.state.reference,
      "datetime": this.state.date + 'T' + this.state.time + ':00.000+0200',
      "address": this.state.formattedAddress,
      "country": country,
      "whoWillBeOnSiteName": this.state.whoWillBeOnSiteName,
      "whoWillBeOnSitePhone": this.state.whoWillBeOnSitePhone,
      "city": city,
      "street": street,
      "officeName": officeName,
      "lng": this.state.lng.toString(),
      "lat": this.state.lat.toString(),
      "isPackage": this.state.isPackage
    }
    if (this.state.selectedProduct == '0') {
      data['productName'] = "FREE 6 IMAGES"
      data['productPrice'] = 0
    } else {
      data['productName'] = this.state.selectedProductData.Public_Product_Name__c
      data['productPrice'] = this.state.selectedProductData.Shooting_Price__c
    }
    if (this.state.isPackage) {
      data['selectedPackage'] = this.state.selectedPackage
    }
    console.log(data);
    axios.post('https://wesnapp.co.il' + '/api/action/create-order-new',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        this.loading(false)
        console.log(response.data);
        this.setState({accountId: response.data.accountId, contactId: response.data.contactId, orderId: response.data.orderId, requestId: response.data.requestId}, () => {
          this.setState({activeStep: 2})
          this.addParameter('page', '3')
        })
      })
    .catch( (error) => {
    });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={styles.root} style={{backgroundColor: 'white', borderRadius: '35px 35px 0 0px'}}>
        {this.state.isLoading == true && (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(40vh - 40px)'}}/><div><div style={{fontFamily: 'arial', fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}>Please wait, the process may take a few seconds</div></div></div>
        )}
        <SmsDialog main={this}/>
        <SnackbarProvider maxSnack={10}>
          <Grid container spacing={2} dir="ltr" justify="center">
            <Grid item xs={12} sm={12} md={10} xl={8} style={{padding: 20}}>
              <div style={{fontSize: 30, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: 43, marginBottom: 30}}>
                <b>New</b> Order
              </div>
              <div style={{minWidth: '80%'}}>
                <OrderStepper main={this} style={{width: '80%'}}/>
              </div>
            </Grid>
          </Grid>
        </SnackbarProvider>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
