import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CircularProgress from '@material-ui/core/CircularProgress';
import Shooting from './Shooting';
import Order from './Order';
import AuthUser from './AuthUser';
import PhotoShoots from './PhotoShoots';
import { SnackbarProvider, useSnackbar } from 'notistack';
// import { createBrowserHistory } from "history";
import { withRouter } from "react-router";
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Fade from '@material-ui/core/Fade';


var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'red'
  },
  success: { backgroundColor: '#38DFA1', color: 'white', textWeight: 'bold' },
});

// const history = createBrowserHistory();

class Template extends React.Component {

  state = {
    deviceType: 'desktop',
    page: '',
    auth: false,
    isLoading: false
  }

  componentDidMount() {
    // var page = this.getParameterByName('page')
    // this.setState({page: page})
    // this.checkUserToken()
  }

  checkUserToken = () => {
    console.log('get the token');
    var currentToken = localStorage.getItem("wesnapp-token")
    var contactId = localStorage.getItem("contactId")
    var accountId = localStorage.getItem("accountId")
    if (currentToken == null || contactId == null || accountId == null) {
      this.props.history.push("/login");
      this.setState({isLoading: false})
    } else {
      // Check validation
      // contactId & currentToken
      console.log(currentToken);
      this.checkToken(currentToken, accountId)
    }
  }

  checkToken = (currentToken, accountId) => {
    var data = {
      "token": currentToken,
      "accountId": accountId
    }
    console.log("checking token");
    axios.post('https://wesnapp.co.il' + '/api/contact/check-token',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
        if (response.data) {
          console.log(true);
          console.log(this.props.history.location.pathname);
          if (this.props.history.location.pathname.includes('/shoot') == false && !this.props.history.location.pathname.includes('/order') ) {
            this.props.history.push("/photo-shoots");
          }
          this.setState({isLoading: false, auth: true})
        } else {
          console.log(false);
          this.props.history.push("/login");
          this.setState({isLoading: false})
        }
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getPageStyle = () => {
    if (!mobile) {
      return {width: 'calc(100vw - 120px)', minHeight: '100vh', backgroundColor: 'white', display: 'inline-block', borderRadius: '35px 0px 0px 35px', verticalAlign:'top', overflowY: 'scroll', height: '100vh', left: '120px', position: 'sticky', position: '-webkit-sticky', position: 'absolute', top: 0}
      // return {width: 'calc(100vw - 0px)', minHeight: '100vh', backgroundColor: 'white', display: 'inline-block', borderRadius: '35px 0px 0px 35px', verticalAlign:'top', overflowY: 'scroll', height: '100vh', left: '0px', position: 'sticky', position: '-webkit-sticky', position: 'absolute', top: 0}
    } else {
      return {marginTop: 0,  width: '100vw', minHeight: '100vh', backgroundColor: 'white', display: 'inline-block', verticalAlign:'top', overflowY: 'scroll', paddingLeft: 0, paddingRight: 0, backgroundColor: 'rgb(94,44,237)', backgroundColor: 'rgb(94,44,237)'}
    }
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={this.getPageStyle()}>
        {this.state.isLoading == true ? (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/><div><div style={{fontFamily: 'arial', fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}></div></div></div>
        ) : (
          <SnackbarProvider maxSnack={10} classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
          }}>
            <Switch>
              <Route path="/*">
                <Order main={this} history={this.props.history}/>
              </Route>
            </Switch>
          </SnackbarProvider>
        )}
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Template));
