
import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import UploadImages from './UploadImages.js'
import ButtonGroup from '@material-ui/core/ButtonGroup';

var drawerWidth = window.innerWidth <= 500 ? (window.innerWidth * 0.85) : (290);
// var contentShift = window.innerWidth <= 500 ? ({}) : ({
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.easeOut,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   marginRight: 0,
// });

const styles = theme => ({
  root: {
    display: 'flex',
    paddingLeft: 60,
    paddingRight: 60
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white', border: '1px solid rgba(244, 244, 244, 1.0)', boxShadow: '0px 5px 12px -10px rgba(0,0,0,0.16)'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRadius: 0,
    boxShadow: '-3px 0 5px 0 rgba(0,0,0,0.05)',
    outline: 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: window.innerWidth <= 500 ? ({}) : ({
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  })
});

class LandingPage extends React.Component {

  state = {
    open: false,
    deviceType: 'desktop',
    isLoading: false,
    detailsOpen: false,
    selectionOpen: false,
    imagesInPackage: 0,
    confirmSelect: false,
    selectedImages: [],
    selectedImagesString: '',
    selectedInThePast: [],
    openPaymentDialog: false,
    openConfirmDialog: false,
    addingImagesToSalesforce: false,
    editedImages: {thumbnail: [], originals: [], compressed: [], watermarked: [], loading: true, imagesForGallery: []},
    imagesToPayFor: ""
  }

  componentDidMount() {
    const isMobile = window.innerWidth <= 500;
    if (isMobile) {
      this.setState({deviceType: 'mobile'})
      this.setState({open: false})
    } else {
      this.setState({open: true})
    }
  }

  handleDrawerOpen = () => {
    this.setState({open: true})
  }

  handleDrawerClose = () => {
    this.setState({open: false})
  }

  confirmSelection = () => {
    var images = this.state.selectedImages
    var imagesString = ""
    if (images.length >= 1) {
      imagesString = images[0].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
      if (images.length > 1) {
        for (var i = 1; i < images.length; i++) {
          imagesString += "," + images[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
        }
      }
      if (this.state.selectedImages.length > this.state.imagesInPackage) {
        this.setState({selectedImagesString: imagesString}, () => {
          var counter = this.state.selectedImages.length - this.state.imagesInPackage
          console.log("counter");
          console.log(counter);
          console.log("selected images");
          console.log(this.state.selectedImages);
          console.log("selected in the past");
          console.log(this.state.selectedInThePast);
          var imagesToPayFor = ""
          for (var i = 0; i < this.state.selectedImages.length; i++) {
            if (!this.state.selectedInThePast.includes(this.state.selectedImages[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).'))) {
              if (counter != 0) {
                if (imagesToPayFor == "") {
                  imagesToPayFor = this.state.selectedImages[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
                } else {
                  var imageString = this.state.selectedImages[i].replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
                  imagesToPayFor = imagesToPayFor + "," + imageString
                }
                counter -= 1
              }
            }
          }
          console.log("PAY FOR");
          console.log(imagesToPayFor.split(','));
          this.setState({imagesToPayFor: imagesToPayFor}, () => {
            // console.log(imagesToPayFor);
            this.setState({openPaymentDialog: true})
          })
        })
        // alert("Pay more")
      } else {
        console.log("not pay");
        // Add images to salesforce
        this.setState({selectedImagesString: imagesString}, () => {
          this.setState({openConfirmDialog: true})
        })
      }
      // alert(imagesString)
    }
  }


  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              <img src={require('../../Assets/Images/wesnappLogoBlack.png')} style={{float: 'left',height: 30, marginTop: 10, marginLeft: 0, marginBottom: 10}}/>
            </Typography>
            {this.state.open == false && (
              <div style={{color: "white", position:"absolute", float: 'right', right: '0', marginRight: 20}}>
                <Button onClick={() => this.setState({open: true})} elevation={0} style={{ textTransform: 'none', marginTop: 0, height: 40,  borderRadius: 20, color: 'rgb(90,88,110)', border: '1px solid rgb(90,88,110)', background: "linear-gradient(to right , #ffffff, #ffffff)"}} >
                  <ShoppingCart style={{marginRight: 8}}/><b>{this.state.selectedImages.length} / {this.state.imagesInPackage}</b>
                </Button>
              </div>
            )}
            {this.state.open == false && (
              <div style={{color: "white", position:"absolute", float: 'right', right: '0', marginRight: 125}}>
              {this.state.deviceType != 'desktop' && (
                <Button onClick={() => this.setState({detailsOpen: true})} elevation={0} style={{ textTransform: 'none', marginTop: 0, height: 40,  borderRadius: 20, color: 'rgb(90,88,110)', border: '1px solid rgb(90,88,110)', background: "linear-gradient(to right , #ffffff, #ffffff)"}} >
                  <b>Details</b>
                </Button>
              )}
              </div>
            )}
            {
            // <IconButton
            //   color="inherit"
            //   aria-label="open drawer"
            //   edge="end"
            //   onClick={this.handleDrawerOpen}
            //   className={clsx(this.state.open && classes.hide)}
            // >
            //   <MenuIcon style={{color:'black'}} />
            // </IconButton>
            }
          </Toolbar>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: this.state.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <UploadImages main={this} />
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
            <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 24, color: '#333', textAlign: 'left', marginBottom: 25, marginTop: 25, marginLeft: 14}}>My Selection</div>
          <Divider />
            <div style={{width: '100%', marginBottom: 20, fontFamily: 'arial', fontWeight: 'thin'}}>
              <div style={{marginTop: 20, marginLeft: 5}}>
                <div style={{width: '100%'}}>
                  <div style={{fontSize: 22, marginRight: 9, marginLeft: 9, marginTop: 0, textAlign: 'left'}}>
                    <b>{this.state.selectedImages.length + " / " + this.state.imagesInPackage + " Photos"}</b>
                  </div>
                  <div style={{fontSize: 18, color: 'gray', marginRight: 9, marginLeft: 9, marginTop: 3, textAlign: 'left'}}>
                    + {this.state.selectedImages.length > this.state.imagesInPackage ? (this.state.selectedImages.length - this.state.imagesInPackage) : (0)} additional x {16} ILS
                  </div>
                  {this.state.selectedImages.length > this.state.imagesInPackage && (
                    <div style={{fontSize: 22, marginRight: 9, marginLeft: 9, marginTop: 0, paddingTop: 0, textAlign: 'left'}}>
                      <div style={{height: 20, width: '100%'}}></div>
                      <div style={{fontSize: 20, fontWeight: 'bold', marginBottom: 10}}>Additional amount</div>
                      <div style={{fontSize: 22, color: 'rgba(124,74,250,1.0)'}}><b>{(this.state.selectedImages.length - this.state.imagesInPackage) * 16} ILS Tax excluded</b></div>
                    </div>
                  )}
                  <div style={{height: 15, width: '100%'}}></div>
                  {this.state.confirmSelect && (
                    <div>
                      <Divider />
                      <div style={{textAlign: 'left', marginLeft: 8}}>
                        <Button onClick={()=> this.confirmSelection()} variant="contained" type="submit" elevation={0} style={{color: 'white', marginTop: 25, marginBottom: 5, height: 48, borderRadius: 24, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
                          Confirm Selection
                        </Button>
                      </div>
                      <div style={{height: 20, width: '100%'}}></div>
                      <Divider />
                    </div>
                  )}
                </div>
                {
                // <Divider />
                //   <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 24, color: '#333', textAlign: 'left', marginBottom: 25, marginTop: 25, marginLeft: 14}}>Logo</div>
                // <Divider />
                // <div style={{marginTop: 25, marginBottom: 10, fontSize: 18, fontWeight: 'bold', width: '100%', textAlign: 'left', marginLeft: 14}}>Position</div>
                // <div style={{width: 'calc(100% - 28px)', marginLeft: 14}}>
                //   <ButtonGroup size="small" aria-label="small outlined button group" fullWidth>
                //    <Button>Top Left</Button>
                //    <Button>Top Right</Button>
                //   </ButtonGroup>
                //   <ButtonGroup size="small" aria-label="small outlined button group" fullWidth>
                //    <Button>Bottom Left</Button>
                //    <Button>Bottom Right</Button>
                //   </ButtonGroup>
                // </div>
                // <div style={{marginTop: 25, marginBottom: 10, fontSize: 18, fontWeight: 'bold', width: '100%', textAlign: 'left', marginLeft: 14}}>Browse logo file</div>
              }
              </div>
            </div>
        </Drawer>
      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LandingPage);
