import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import SideMenu from './SideMenu';
import MobileSideMenu from './MobileSideMenu';
import View from './View';
import Order from './Order';
import AppBar from './AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createBrowserHistory } from "history";


const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

const history = createBrowserHistory();

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgb(127,100,246)'
  }
});

class Template extends React.Component {

  state = {
    deviceType: 'desktop',
    page: '',
    sideMenuOpen: false
  }

  componentDidMount() {
    // var page = this.getParameterByName('page')
    // this.setState({page: page})
    // if ((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)) {
    //   this.setState({deviceType: 'mobile'})
    // } else {
    //   this.setState({deviceType: 'desktop'})
    // }
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={styles.root}>
        {
        //   this.state.deviceType != 'desktop' && (
        //   <AppBar main={this}/>
        // )
      }
        <Router history={history}>
        <div>
        <div style={{display: 'inline-block'}}>
          {
            // this.state.deviceType == 'desktop' ? (<SideMenu main={this}/>) : (<MobileSideMenu main={this}/>)
          }
        </div>
        <div style={{display: 'inline-block'}}>
        {
          // <View  main={this} history={history}/>
        }
          <Order />
        </div>
        </div>
        </Router>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
