// B1 - business and B2 - visitor
// L1 -
// E2 - Invester Visa
// E1 - International Trader
// National Interest
// Lottery

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import axios from 'axios';
import Cookies from 'universal-cookie';
import uuid from 'react-native-uuid';
import ImageUploader from 'react-images-upload';
import '../../App.css';
// import RawOrBrackets from './RawOrBrackets.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Star from '@material-ui/icons/Star';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Gallery from 'react-grid-gallery';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import GifLoader from 'react-gif-loader';
import Avatar from '@material-ui/core/Avatar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Lightbox from 'react-image-lightbox';
import CircularProgress from '@material-ui/core/CircularProgress';

// import ReactImageProcess from "react-image-process";
import { AuthController } from 'react-controllers'
// import LazyLoad from 'react-lazy-load';
import LazyLoad from 'react-lazyload'
import Details from './Details.js'
import Selection from './Selection.js'
import Tooltip from '@material-ui/core/Tooltip';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

import 'react-image-lightbox/style.css';

import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const BASE_URL = 'https://wesnapp.co.il/py/server/upload/';
const GET_RAW_IMAGES = 'https://wesnapp.co.il/py/server/get-compressed-raws/';
const GET_BRACKETS_IMAGES = 'https://wesnapp.co.il/py/server/get-compressed-brackets/';
const GET_EDITED_IMAGES = 'https://wesnapp.co.il/py/server/get-edited/';
const GET_EDITED_WATERMARKED_IMAGES = 'https://wesnapp.co.il/py/server/get-edited-watermarked/';
const GET_ANGEL_IMAGES = 'https://wesnapp.co.il/py/server/get-angel-images/';

const GET_BRACKETS_ORGENIZED_IMAGES = 'https://wesnapp.co.il/py/server/get-images-orgenized/'
const GET_EDITED_ORGENIZED_IMAGES = 'https://wesnapp.co.il/py/server/get-edited-images-orgenized/'

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 20,
    backgroundColor: 'rgb(249,250,252)'
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#04e259',
  },
})(LinearProgress);

const uploadIcon = require("../../Assets/Images/uploadIcon.png");
const downloadIcon = require("../../Assets/Images/downloadIcon.png");

// const loadingGif = require("../../Assets/Images/loadingGif.gif");
const vIcon = require("../../Assets/Images/vIcon.png");
const menuIcon = require("../../Assets/Images/menuIcon.png");
const xIcon = require("../../Assets/Images/xIcon.png");
const zoomIn = require("../../Assets/Images/zoomIn.png");

//https://www.iconfinder.com/icons/2561262/cloud_download_icon
const trashIcon = require("../../Assets/Images/trashTableIcon.png");
const zoomInIcon = require("../../Assets/Images/zoomin_icon.png");
const downloadTIcon = require("../../Assets/Images/downloadTableIcon.png");
const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

const alertIcon = require("../../Assets/Images/alertIcon.png");
const successIcon = require("../../Assets/Images/successIcon.png");
// const zipIcon = require("../../Assets/Images/zipIcon.png");

const selectedIcon = require("../../Assets/Images/selected.png");
const unselectedIcon = require("../../Assets/Images/unselected.png");
const confirmedIcon = require("../../Assets/Images/confirmed.png");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: 10
  }
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

class NewsPage extends React.Component {
  state = {
    loading: false,
    loadingData: true,
    requestId: '',
    contactName: '',
    shootingLocation: '',
    dateAndTime: '',
    productName: '',
    firstSelection: false,
    avatar: '',
    mobilePhone: '',
    userComment: '',
    photographerName: '',
    numberOfImages: 0,
    downloadPercenteges: 0,
    confirmSelect: false,
    selectedImages: [],
    pricePerExtra: [],
    galleryIsOpen: false,
    galleryIndex: 0,
    openConfirmDialog: false,
    openPaymentDialog: false,
    addingImagesToSalesforce: false,
    openReviewDialog: false,
    addingComment: false,
    openSnack: false,
    photographerRate: 0,
    photosRate: 0,
    imagesToPayFor: ""
  };

  // constructor(props) {
  //    super(props);
  //    //setClick={click => this.handleClosePaymentDialog = click}
  //    // this.handleClosePaymentDialog = this.handleClosePaymentDialog.bind(this);
  // }

   componentDidMount() {
     // this.props.main.setState({selectedImages: ['1','2','3'], selectedInThePast: ['1','2','3']}, () => {
     //   this.props.main.setState({selectedInThePast: this.props.main.state.selectedImages}, () => {
     //     this.props.main.setState({selectedImages: ['1','2','3','4']}, () => {
     //        console.log(this.props.main.state.selectedInThePast);
     //     })
     //   })
     // })
    this.getDataOfRequest()
  }

  handleOpenPaymentDialog = () => {
    this.setState({openPaymentDialog: true})
  }

  handleCloseReviewDialog = () => {
    this.setState({openReviewDialog: false})
  }

  handleCloseConfirmDialog = () => {
    this.setState({openConfirmDialog: false})
    this.props.main.setState({openConfirmDialog: false})
  }

  handleClosePaymentDialog = () => {
    this.getDataOfRequest()
    this.props.main.setState({openConfirmDialog: false})
    this.props.main.setState({openPaymentDialog: false})
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  sendSMS = () => {
    var phone = '+972' + this.state.mobilePhone
    console.log(phone);
    var data = {code: '+972', text: 'All images successfully uploaded!', phone: phone}
    axios.post("https://wesnapp.co.il/api/sms/send-message",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  getNewSelectedImages = async () => {
    try {
      var data = {id: this.state.requestId}
      const response = await axios.post("https://wesnapp.co.il/api/request/get-full-data", data);
      console.log(response.data);
      var selectedImages = []
      var selectedInThePastString = []
      var selectedInThePast = []
      if (response.data.request.Selected_Images__c != null) {
        selectedInThePastString = response.data.request.Selected_Images__c.replace(new RegExp('<p>', 'g'), '').replace(new RegExp('</p>', 'g'), '').replace(new RegExp('<br>', 'g'), '')
        while (selectedInThePastString.includes('1.')) {
          selectedInThePastString = selectedInThePastString.replace('1.JPG', '(1).JPG').replace('1.png', '(1).png').replace('1.JPEG', '(1).JPEG').replace('1.jpeg', '(1).jpeg')
        }
        selectedInThePast = selectedInThePastString.split(',')
        selectedImages = selectedInThePastString.split(',')
        this.props.main.setState({selected: selectedImages.length})
      }
      // pricePerExtra: response.data.product.Price_Per_Extra_Product__c
      this.props.main.setState({selectedImages: selectedImages, selectedInThePast: selectedInThePast, confirmSelect: false}, () => {
        this.getEditedImages()
      })
      } catch (err) {
          console.error(err);
      }
  };

  getDataOfRequest = async () => {
    try {
        var id = this.getParameterByName('id')
        if (id != null && id != undefined && id != "") {
          this.setState({requestId: id})
          var data = {id: id}
          const response = await axios.post("https://wesnapp.co.il/api/request/get-full-data", data);
          console.log(response.data);
          var dateAndTime = await this.getDateAndTime(response.data.request.Shooting_Date_Time__c)
          this.setState({loadingData: false})
          this.setState({requestData: response.data,
            photographerName: response.data.request.Photographer_Name__c,
            requestName: response.data.request.Name,
            contactName: response.data.contact.Name,
            numberOfImages: response.data.request.Total_Image_For_Costumer_To_Download__c,
            mobilePhone: response.data.contact.MobilePhone,
            shootingLocation: response.data.request.ShootingStreet__c + " " + response.data.request.ShootingCity__c,
            productName: response.data.product.Name,
            avatar: "AA"}, () => {
              this.props.main.setState({imagesInPackage: response.data.request.Total_Image_For_Costumer_To_Download__c})
              var selectedImages = []
              var selectedInThePastString = []
              var selectedInThePast = []
              if (response.data.request.Rating_Shooting__c != null) {
                this.setState({photosRate: response.data.request.Rating_Shooting__c})
              }
              if (response.data.request.Rating_Photographer__c != null) {
                this.setState({photographerRate: response.data.request.Rating_Photographer__c})
              }
              if (response.data.request.Comment_user__c != null) {
                this.setState({userComment: response.data.request.Comment_user__c})
              }
              if (response.data.request.Selected_Images__c != null) {
                selectedInThePastString = response.data.request.Selected_Images__c.replace(new RegExp('<p>', 'g'), '').replace(new RegExp('</p>', 'g'), '').replace(new RegExp('<br>', 'g'), '')
                while (selectedInThePastString.includes('1.')) {
                  selectedInThePastString = selectedInThePastString.replace('1.JPG', '(1).JPG').replace('1.png', '(1).png').replace('1.JPEG', '(1).JPEG').replace('1.jpeg', '(1).jpeg')
                }
                selectedInThePast = selectedInThePastString.split(',')
                selectedImages = selectedInThePastString.split(',')
                if (selectedImages.length == 0) {
                  this.setState({firstSelection: true})
                }
                this.props.main.setState({selected: selectedImages.length})
              }
              // pricePerExtra: response.data.product.Price_Per_Extra_Product__c
              this.props.main.setState({selectedImages: selectedImages, selectedInThePast: selectedInThePast, confirmSelect: false}, () => {
                this.getEditedImages()
              })
            })
        } else {
            this.setState({loading: false})
            alert('Shooting ID is not valid.')
        }
      } catch (err) {
          console.error(err);
      }
  };

  async getEditedImages  ()  {
    console.log("DOWNLOADING EDITED");
    const response = await axios.get(GET_EDITED_ORGENIZED_IMAGES + this.state.requestId + '-edited',{}, {headers: {'Content-Type': 'application/json'}});
    console.log(response.data)
    var data = response.data
    var imagesForGallery = []
    for (var i = 0; i < data.watermarked.length; i++) {
      console.log(this.props.main.state.selectedInThePast);
      if (this.props.main.state.selectedInThePast.includes(data.originals[i])) {
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth:  320,
          thumbnailHeight: 174,
          isSelected: true,
          caption: "Image " + String(i)
        })
      } else {
        imagesForGallery.push({
          src: data.watermarked[i],
          thumbnail: data.watermarked[i],
          thumbnailWidth:  320,
          thumbnailHeight: 174,
          isSelected: false,
          caption: "Image " + String(i)
        })
      }
    }
    data['loading'] = false
    data['imagesForGallery'] = imagesForGallery
    this.setState({addingImagesToSalesforce: false, openConfirmDialog: false})
    this.props.main.setState({editedImages: data, addingImagesToSalesforce: false, openConfirmDialog: false})
    return ""
  }

  removeDraft = (url) => {
    return url.replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).')
  }

  onSelectImage = (index) => {

    console.log("ALREADY SELECTED");
    console.log(this.props.main.state.selectedInThePast);
    console.log("@@@@@@@@@@@@@@@@@");
    var inThePast = this.props.main.state.selectedInThePast
    var inThePastSave = this.props.main.state.selectedInThePast

    var images = this.props.main.state.editedImages;
    var img = images.imagesForGallery[index];

    var selectedImages = this.props.main.state.selectedImages

    console.log(this.props.main.state.editedImages.imagesForGallery[index]);

    if (!inThePast.includes(this.removeDraft(img.src))) {
      console.log("in the past1");
      console.log(inThePastSave);
      if(img.hasOwnProperty("isSelected")) {
        if (!inThePast.includes(this.removeDraft(images.imagesForGallery[index].src))) {
          console.log("in the past2");
          console.log(inThePastSave);
          images.imagesForGallery[index].isSelected = !img.isSelected
          if (img.isSelected == true) {
            console.log("in the past3");
            console.log(inThePastSave);
            selectedImages.push(this.removeDraft(this.props.main.state.editedImages.imagesForGallery[index].src))
            console.log("in the past4");
            console.log(inThePastSave);
          } else {
            var index = selectedImages.indexOf(this.removeDraft(images.imagesForGallery[index].src));
            if (index > -1) {
              selectedImages.splice(index, 1);
            }
          }
          // console.log("selected images");
          // console.log(selectedImages);
          // console.log("in the past1");
          // console.log(this.props.main.state.selectedInThePast);
          // console.log("in the past2");
          // console.log(inThePastSave);
          if (selectedImages.length > this.props.main.state.selectedInThePast.length) {
            this.props.main.setState({selectedImages: selectedImages, confirmSelect: true, editedImages: images})
          } else {
            this.props.main.setState({selectedImages: selectedImages, confirmSelect: false, editedImages: images})
          }
        }
      }
      // this.props.main.setState({
      //     editedImages: images
      // });
    }
  }

  uploadDownloadProgress = () => {
    return (progress) => {
      console.log(progress.loaded);
      console.log(progress.total);
      var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
      this.setState({downloadPercenteges: percentCompleted}, () => {})
    }
  }

  downloadZipFile = (array, zipname) => {
    console.log(array);
    if (this.state.firstSelection) {
      this.setState({openReviewDialog: true})
    }
    if (this.state.downloadPercenteges == 0) {
      var lineOfFiles = ''
      for (var i = 0; i < array.length; i++) {
        var path = ''
        console.log(array[i]);
        path = array[i].replace('https://s3.amazonaws.com/wesnapp-development/', '')
        path.replace('/','-')
        if (lineOfFiles == '') {
          lineOfFiles = path
        } else {
          lineOfFiles = lineOfFiles + ',' + path
        }
      }
      this.setState({downloadPercenteges: 1})
      var config = {
        responseType: 'blob',
        onDownloadProgress: this.uploadDownloadProgress(),
        timeout: 20000
      };
      var filesOld = lineOfFiles.split(',')
      var files = []
      var folder = filesOld[0].split("/" + filesOld[0].split('/')[filesOld[0].split('/').length - 1])[0]
      for (var j = 0; j < filesOld.length; j++) {
        files.push(filesOld[j].split(folder + '/')[1])
      }
      console.log('folder');
      console.log(folder);
      console.log(files);
      axios.post('https://wesnapp.co.il/api/s3/zipfiles',{folder: folder, files: files, zipname: zipname}, config).then((response) => {
        console.log(response.data);
        var headers = response.headers;
       var blob = new Blob([response.data],{type:headers['content-type']});
       var link = document.createElement('a');
       link.href = window.URL.createObjectURL(blob);
       link.download = zipname;
       link.click();
       link.remove()
       this.setState({downloadPercenteges: 0})
      })
      .catch( (error) => {
        console.log(error);
      });
    }
  }

  getDateAndTime = async (dateAndTime) => {
    var dateObj = new Date(dateAndTime);
    var stringToReturn = ''
    stringToReturn += dateObj.getDate() + "/"
    stringToReturn += (dateObj.getMonth() + 1) + "/"
    stringToReturn += dateObj.getFullYear() + " "
    var hour = dateObj.getHours()
    if (String(hour).length < 2) {
      hour = "0" + hour
    }
    var min = dateObj.getMinutes()
    if (String(min).length < 2) {
      min = "0" + min
    }
    stringToReturn += hour + ":"
    stringToReturn += min
    this.setState({dateAndTime: stringToReturn})
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  selectAll = () => {
    var originals = this.props.main.state.editedImages
    var images = []
    for (var i = 0; i < originals.imagesForGallery.length; i++) {
      images.push(originals.imagesForGallery[i].src.replace('watermarked/', '').replace('_reduced_watermarked', '').replace('1.', '(1).'))
      originals.imagesForGallery[i].isSelected = true
    }
    this.props.main.setState({selectedImages: images, editedImages: originals, selected: images.length, confirmSelect: true})
  }

  addImagesToSelection = () => {
    console.log("!@#!@#!@#!@#!@#!@#!@#!@#!@#!@#!@#");
    this.props.main.setState({addingImagesToSalesforce: true})
    var data = {requestId: this.state.requestId, data: {Selected_Images__c: this.props.main.state.selectedImagesString, Status__c: 'Process Completed'} }
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
      // var newSelectedImages = this.props.main.state.selectedImages
      //selectedInThePast: this.props.main.state.selectedImages,
      this.props.main.setState({openConfirmDialog: false, addingImagesToSalesforce: false}, () => {
        console.log("SELECTED IN THE PAST");
        console.log(this.props.main.state.selectedInThePast);
      })
      this.getNewSelectedImages()
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  handleChange = name => event => {
    console.log(name);
    console.log(event.target.value);
    this.setState({[name]: event.target.value})
  };

  addReview = () => {
    this.setState({addingComment: true})

    var data = {requestId: this.state.requestId, data: {Comment_user__c: this.state.userComment} }
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
        this.setState({openSnack: true, addingComment: false})
        this.handleCloseReviewDialog()
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  setRatingForPhotographer = (value) => {
    this.setState({photographerRate: value})
    var data = {requestId: this.state.requestId, data: {Rating_Photographer__c: value}}
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  setRatingForPhotos = (value) => {
    this.setState({photosRate: value})
    var data = {requestId: this.state.requestId, data: {Rating_Shooting__c: value}}
    axios.post("https://wesnapp.co.il/api/request/update-request",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
      <Details main={this.props.main} mainScreen={this}/>
      <Selection main={this.props.main} mainScreen={this}/>
      <Dialog
        open={this.props.main.state.openPaymentDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClosePaymentDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Additional media</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You've just selected additional media. You will get charged for those. If would you like to proceed with the download, please complete the payment proccess.
          </DialogContentText>
          <div style={{marginTop: 20, width: '100%', height: 5}}></div>
          {this.props.main.state.openPaymentDialog && (
            <iframe style={{width: '100%', height: 290, marginTop: 0, frameBorder: 0, borderRadius: 0, border: 0}} src={"https://direct.tranzila.com/wesnapp/iframenew.php?cy=1&cred_type=1&u71=1&trButtonColor=000000&buttonLabel=Payment&type=chargeForExtra&nologo=1&sum=" + ((this.props.main.state.selectedImages.length - this.state.numberOfImages) * 1 * 1.17).toFixed(2) + "&price=" + ((this.props.main.state.selectedImages.length - this.props.main.state.selectedInThePast.length) * 1)	 + "&accountId=" + this.state.requestData.contact.AccountId + "&orderId=" + this.state.requestData.request.WS_Order__c + "&mobile=" + this.state.requestData.contact.MobilePhone + "&name=" + this.state.requestData.contact.Name  + "&vatType=" + "0" + "&email=" + this.state.requestData.contact.Email + "&companyNumber=" + "" + "&address=" + this.state.requestData.request.ShootingStreet__c + ", " + this.state.requestData.request.ShootingCity__c + "&city=" + this.state.requestData.request.ShootingCity__c + "&productName=" + this.state.requestData.product.Name + "&countryCode=" + "IL" + "&currencyCode=" + "ILS&contact=" + this.state.requestData.contact.AccountId + "&pdesc=" + this.props.main.state.selectedImagesString + "&remarks=" + this.state.requestData.request.Id + "&company=" + this.props.main.state.imagesToPayFor}></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClosePaymentDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.openConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Select Images</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <b>Note</b> - Once you select photos, you won't be able to change your selection.
          </DialogContentText>
          <div style={{marginTop: 10, width: '100%', height: 2}}></div>
          <center>
          {this.state.addingImagesToSalesforce ? (
            <div style={{marginTop: 25, height: 48}}>
              <CircularProgress size={40}/>
            </div>
          ) : (
            <Button onClick={()=> this.addImagesToSelection()} variant="contained" type="submit" elevation={0} style={{color: 'white', marginTop: 25, height: 48, borderRadius: 24, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 20px -10px rgba(0,0,0,0.30)"}} >
              Confirm Selection
            </Button>
          )}
          </center>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.openSnack}
        autoHideDuration={6000}
        onClose={() => this.setState({openSnack: false})}
      >
        <MySnackbarContentWrapper
          onClose={() => this.setState({openSnack: false})}
          variant="success"
          message="Thank you. We got your review!"
        />
      </Snackbar>
      <Dialog
        open={this.state.openReviewDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseReviewDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Write us a review!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <b>We want to hear you! Please share with us how was the photographer and what do you think about the photos.</b>
          </DialogContentText>
          <Box component="fieldset" mb={1} borderColor="transparent">
             <Typography component="legend1">Rate the photographer</Typography>
             <Rating
               name="simple-controlled1"
               value={this.state.photographerRate}
               onChange={(event, newValue) => {
                 this.setRatingForPhotographer(newValue)
               }}
             />
           </Box>
           <Box component="fieldset" mb={1} borderColor="transparent" style={{marginTop: -10}}>
              <Typography component="legend2">Rate the photos</Typography>
              <Rating
                name="simple-controlled2"
                value={this.state.photosRate}
                onChange={(event, newValue) => {
                  this.setRatingForPhotos(newValue)
                }}
              />
            </Box>
          <div style={{marginTop: -12, width: '100%', height: 2}}></div>
          <TextField
            id="outlined-multiline-static"
            label=""
            multiline
            rows="4"
            value={this.state.userComment}
            style={{width: '100%'}}
            onChange={this.handleChange('userComment')}
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          {this.state.addingComment ? (
            <div style={{marginTop: 15, height: 38}}>
              <CircularProgress size={30}/>
            </div>
          ) : (
            <Button onClick={() => this.addReview()} color="primary">
              Add Review
            </Button>
          )}
          <Button onClick={this.handleCloseReviewDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {this.state.galleryIsOpen && (
        <Lightbox
          mainSrc={this.props.main.state.editedImages.imagesForGallery[this.state.galleryIndex].src}
          nextSrc={this.props.main.state.editedImages.imagesForGallery[(this.state.galleryIndex + 1) % this.props.main.state.editedImages.imagesForGallery.length].src}
          prevSrc={this.props.main.state.editedImages.imagesForGallery[(this.state.galleryIndex + this.props.main.state.editedImages.imagesForGallery.length - 1) % this.props.main.state.editedImages.imagesForGallery.length].src}
          onCloseRequest={() => {this.setState({ galleryIsOpen: false });this.props.main.setState({open: true})}}
          onMovePrevRequest={() =>
            this.setState({
              galleryIndex: (this.state.galleryIndex + this.props.main.state.editedImages.imagesForGallery.length - 1) % this.props.main.state.editedImages.imagesForGallery.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              galleryIndex: (this.state.galleryIndex + 1) % this.props.main.state.editedImages.imagesForGallery.length,
            })
          }
        />
      )}
      <SnackbarProvider maxSnack={10}>
        {this.state.loading && (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(40vh - 40px)'}}/><div><div style={{fontFamily: 'arial', fontSize: 25, color: 'black', marginTop: 20, fontWeight: 'bold'}}>Loading...</div></div></div>
        )}
        {this.state.deleting && (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(40vh - 40px)'}}/><div><div style={{fontFamily: 'arial', fontSize: 25, color: 'black', marginTop: 20, fontWeight: 'bold'}}>Deleting...</div></div></div>
        )}
              <Grid container spacing={24} dir="ltr" style={{marginTop: 15}}>
                <Grid item xs={12} sm={12} md={12}>
                {this.props.main.state.deviceType == "desktop" ? (
                  <div style={{height: 0, width: '100%'}}></div>
                ) : (
                  <div style={{height: 0, width: '100%', marginTop: -45}}></div>
                )}
                {(this.state.downloadPercenteges != 100 && this.state.downloadPercenteges != 0) && (
                <div style={{width: '100%', marginTop: 25, height: 40}}>
                  {this.props.main.state.deviceType == "desktop" ? (
                    <div style={{marginTop: 0}}>
                      <div style={{width: '100%', textAlign: 'left', color: 'black', marginBottom: 10}}>Download in progress - {this.state.downloadPercenteges}%</div>
                      <BorderLinearProgress variant="determinate" value={this.state.downloadPercenteges} />
                    </div>
                  ) : (
                    <div>
                      <div style={{width: '100%', textAlign: 'left', color: 'black', marginBottom: 10}}>Download in progress - {this.state.downloadPercenteges}%</div>
                      <BorderLinearProgress variant="determinate" value={this.state.downloadPercenteges} />
                    </div>
                  )}
                  </div>
                )}
                  {this.props.main.state.deviceType == "desktop" && (
                    <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 30, color: '#333', textAlign: 'left', marginBottom: 25, marginTop: 0}}>Hey {this.state.contactName}, Your images from {this.state.shootingLocation} are ready!</div>
                  )}
                  <div style={{width: '100%', marginBottom: 20, fontFamily: 'arial', fontWeight: 'thin'}}>
                  {this.state.loadingData ? (
                    <div>
                      {this.props.main.state.deviceType == "desktop" && (
                        <div style={{height: 60}}>
                          <div>
                            <CircularProgress size={25}/>
                          </div>
                          <div style={{marginTop: 5}}>
                            <b>Loading Data</b>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                    {this.props.main.state.deviceType == "desktop" && (
                      <div style={{marginTop: 40}}>
                        <div style={{width: '17.5%', verticalAlign: 'top', display: 'inline-block', height: 60}}>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginLeft: 9}}>
                            <img className={"buttonIcon"} style={{width: 25, height: 25}} src={cameraIcon} alt="..." />
                          </div>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 0}}>
                            {this.state.photographerName}
                          </div>
                        </div>
                        <div style={{width: '17.5%', verticalAlign: 'top', display: 'inline-block', height: 60}}>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginLeft: 9}}>
                            <img className={"buttonIcon"} style={{width: 25, height: 25}} src={userIcon} alt="..." />
                          </div>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 0}}>
                            {this.state.contactName}
                          </div>
                        </div>
                        <div style={{width: '17.5%', verticalAlign: 'top', display: 'inline-block'}}>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginLeft: 9}}>
                            <img className={"buttonIcon"} style={{width: 25, height: 25}} src={locationIcon} alt="..." />
                          </div>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 0}}>
                            {this.state.shootingLocation}
                          </div>
                        </div>
                        <div style={{width: '17.5%', verticalAlign: 'top', display: 'inline-block'}}>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginLeft: 9}}>
                            <img className={"buttonIcon"} style={{width: 25, height: 25}} src={dateIcon} alt="..." />
                          </div>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 0}}>
                            {this.state.dateAndTime}
                          </div>
                        </div>
                        <div style={{width: '17.5%', verticalAlign: 'top', display: 'inline-block'}}>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginLeft: 9}}>
                            <img className={"buttonIcon"} style={{width: 25, height: 25}} src={imageIcon} alt="..." />
                          </div>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 0}}>
                            {this.state.productName}
                          </div>
                        </div>
                      </div>
                    )}
                    </div>
                  )}
                  </div>
                </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div style={{width: '100%', marginBottom: 0}}>
            {this.props.main.state.deviceType == "desktop" ? (
              <div>
              <div style={{display: 'inline-block', float: 'left', fontWeight: 'bold', fontFamily: 'arial', fontSize: 25, textAlign: 'left', color: '#333', marginBottom: 19}}>Photos</div>
              <div style={{display: 'inline-block',fontWeight: 'bold', float: 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginBottom: 19, marginTop: 0}}>
              {this.props.main.state.selectedInThePast.length > 0 && (
                <Tooltip title={"Download Zip"} placement="top">
                  <Button onClick={() => this.downloadZipFile(this.props.main.state.selectedInThePast, 'WeSnapp_' + this.state.requestName + '_edited.zip')} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, backgroundColor: "rgb(0,230,18)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
                    <CloudDownload style={{marginRight: 8}}/> Download
                  </Button>
                </Tooltip>
              )}
              </div>
              <div style={{display: 'inline-block',fontWeight: 'bold', float: 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginBottom: 19, marginTop: 0, marginRight: 15}}>
                {(this.props.main.state.selectedInThePast.length != this.props.main.state.editedImages.imagesForGallery.length && this.state.selectedImages.length != this.props.main.state.editedImages.imagesForGallery.length) && (
                  <Button onClick={()=> this.selectAll()} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
                    Select All
                  </Button>
                )}
              </div>
              {
              // <div style={{display: 'inline-block',fontWeight: 'bold', float: 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginBottom: 19, marginTop: 0, marginRight: 15}}>
              //   <Button onClick={()=> this.setState({openReviewDialog: true})} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
              //     <Star style={{marginRight: 8}}/> Review
              //   </Button>
              // </div>
            }
              <div style={{width: '100%', height: 70}}></div>
              </div>
            ) : (
            <div>
              <div style={{width: '100%', textAlign: 'left', fontWeight: 'bold', fontFamily: 'arial', fontSize: 25, textAlign: 'left', color: '#333', marginBottom: 19, marginTop: 0}}>Photos</div>
                <div style={{width: '100%', marginTop: 15, marginBottom: 15}}>
                {
                  // <div style={{display: 'inline-block',fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginRight: 0, marginLeft: '0.6%'}}>
                  //   <Button onClick={()=> this.setState({openReviewDialog: true})} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
                  //     Review
                  //   </Button>
                  // </div>
                }
                  <div style={{display: 'inline-block',fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginLeft: '0.6%'}}>
                    {this.props.main.state.selectedInThePast.length > 0 && (
                      <Button onClick={() => this.downloadZipFile(this.props.main.state.selectedInThePast, 'WeSnapp_' + this.state.requestName + '_edited.zip')} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, backgroundColor: "rgb(0,230,18)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)", width: '100%'}} >
                        {
                          //<CloudDownload/>
                        }
                        Download
                      </Button>
                    )}
                  </div>
                  <div style={{display: 'inline-block',fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#333', marginRight: 0, marginLeft: '0.6%'}}>
                    {(this.props.main.state.selectedInThePast.length != this.props.main.state.editedImages.imagesForGallery.length && this.state.selectedImages.length != this.props.main.state.editedImages.imagesForGallery.length) && (
                      <Button onClick={()=> this.selectAll()} variant="contained" type="submit" elevation={0} style={{color: 'white', height: 40, borderRadius: 20, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
                        Select All
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            </div>
              { this.props.main.state.editedImages.loading && (
                <center>
                  <CircularProgress style={{marginTop: 20}}/>
                  <div style={{marginTop: 20, fontSize: 20, fontFamily: 'arial', color: 'black'}}>Loading Images</div>
                </center>
              )}
              {this.props.main.state.editedImages.imagesForGallery.length > 0 && (
                <div>
                <div style={{display: 'inline-block', width: 'calc(100% - 0px)', verticalAlign:'top'}}>
                  {this.props.main.state.editedImages.imagesForGallery.map((item, i) => (
                    <div style={this.props.main.state.deviceType == "desktop" ? {display: 'inline-block', width: '24%',marginBottom: 10, marginLeft: 10} : {display: 'inline-block', width: '100%',marginBottom: 10}} >
                      <div className="imgcontainer">
                        <img src={item.src} alt="Avatar" className="image" style={{width: '100%'}} />
                        <div className="middle">
                          <img src={zoomInIcon} alt="Avatar" className="text" width="80px" height="80px" onClick={() => {this.setState({galleryIsOpen: true, galleryIndex: i}); this.props.main.setState({open: false})}}/>
                        </div>
                      </div>
                      <div className="bar">
                        <div style={{display: 'inline-block', fontSize: '19px', marginTop: -3}}>Photo #{i+1}</div>
                        {this.props.main.state.selectedInThePast.includes(item.src.replace('_reduced_watermarked', '').replace('watermarked/','').replace('1.', '(1).')) ? (
                          <Tooltip title={"Selected"} placement="top">
                            <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -8, color: 'white', float: 'right', border: 'none', outline: 'none',background: 'transparent'}} onClick={ ()=> this.onSelectImage(i)}>
                              <img src={confirmedIcon} width="25" height="25" style={{background: 'transparent'}}/>
                            </button>
                          </Tooltip>

                        ) : (
                          <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -8, color: 'white', float: 'right', border: 'none', outline: 'none', background: 'transparent'}} onClick={ ()=> this.onSelectImage(i)}>
                          { item.isSelected && (<img src={selectedIcon} width="25" height="25"/>)}
                          { !item.isSelected && (<img src={unselectedIcon} width="25" height="25"/>)}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </SnackbarProvider>
      </div>
    );
  }
}

NewsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NewsPage);
