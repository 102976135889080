import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from 'material-ui-pickers';
import Autocomplete from 'react-google-autocomplete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DateFnsUtils from '@date-io/date-fns';
import Map from './Map';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';


const photographerImage = require("../../Assets/Images/photographerImage2.png");

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: 'rgb(94,44,237)',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: '100%'
  },
  button: {
    marginRight: theme.spacing(1),
    height: 50,
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#39DFA1'
    }
  },
  selectedButton: {
    marginRight: theme.spacing(1),
    height: 50,
    borderRadius: 25,
    backgroundColor: '#39DFA1',
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    color: 'white',
    hover: {
      backgroundColor: '#39DFA1'
    }
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['General Details', 'Shooting Details', 'Summary and Payment'];
}

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const steps = getSteps();

  const getCoordinates = async (address) => {
    try {
      var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURI(address) + "&key=AIzaSyDJ7KNKLQOtQqlx871XHHU9EcZRt2K4qso"
      const response = await axios.get(link);
      console.log(response.data.results[0]);
      if (response.data.results.length > 0) {
        if (response.data.results[0].types.includes('street_address') || response.data.results[0].types.includes('premise')) {
          console.log("yes");
          props.main.setState({errorAddress: false, lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng, formattedAddress: response.data.results[0].formatted_address})
        } else {
          console.log("no");
          props.main.setState({errorAddress: true})
        }
      } else {
        props.main.setState({errorAddress: true})
      }
    } catch (err) {
        console.error(err);
    }
  }


  const handleNext = () => {
    // setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    props.main.setState({activeStep: props.main.state.activeStep - 1})
  };

  const handleReset = () => {
    props.main.setState({activeStep: 0})
  };

  const handleTimeChangeFrom = date => {
    var dateObj = new Date(date);
    var ilTime = new Date().toLocaleString("en-US", {timeZone: 'Asia/Jerusalem'});
    ilTime = new Date(ilTime);
    console.log(ilTime);
    var newTime = String(dateObj.getHours() + ":" + dateObj.getMinutes())
    props.main.setState({time: newTime, formatedTime: new Date(date)})
  }

  const handleDateChangeFrom = date => {
    var dateObj = new Date(date);
    console.log(dateObj);
    var m = String(dateObj.getDate())
    var d = String(dateObj.getMonth()+1)
    var y = String(dateObj.getFullYear())
    if (m.length == 1) {
      m = "0" + m
    }
    if (d.length == 1) {
      d = "0" + d
    }
    var newDate = y + "-" + d + "-" + m
    props.main.setState({date: newDate, formatedDate: new Date(date)})
  };

  const handleChange = name => event => {
    props.main.setState({[name]: event.target.checked})
  };

  const handleChangeText = name => event => {
    if (name == 'phone') {
      props.main.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      props.main.setState({ [name]: event.target.value });
    }
  };

  const getDateTimeToDisplay = () => {
    var dateAndTime = ''
    var splitedDate = props.main.state.date.split('-')
    dateAndTime = splitedDate[1] + "/" +  splitedDate[2] + "/" + splitedDate[0] + " " + props.main.state.time
    return dateAndTime
  }

  const getBalance = (data) => {
    console.log('@#!@#');
    var packages = props.main.state.openPackages
    console.log(packages);
    for (var i = 0; i < packages.length; i++) {
      console.log(packages[i].orderId);
      console.log(data);
      if (packages[i].orderId == data.WS_Order__c) {
        console.log(packages[i].balance);
        return packages[i].balance
      }
    }
  }

  const getProductData = (id, whatType) => {
    var products = props.main.state.productsFromPackages
    // console.log("!@#!@#!@#!@#");
    // console.log(products);
    for (var i = 0; i < products.length; i++) {
      // console.log(products[i].Id.slice(0, -3));
      // console.log(id);
      if (products[i].Id.slice(0, -3) == id) {
        if (whatType == 'all') {
          return products[i]
        }
        // console.log("!!!!");
        // console.log(products[i][whatType]);
        return products[i][whatType]
      }
    }
    return ''
  }

  return (
    <div className={classes.root}>
      <Stepper style={{marginTop: -30, width: '100%'}} alternativeLabel activeStep={props.main.state.activeStep} connector={<QontoConnector />}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {props.main.state.activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div style={{minWidth: '80%'}}>
            <Typography className={classes.instructions}>
            {props.main.state.activeStep == 2 && (
              <div style={{marginTop: mobile ? 0 : 20, minWidth: '80vw' }}>
                <Grid container spacing={2} dir="ltr" justify="center">
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                      <div style={{padding: mobile ? 20 : 30, borderRadius: 20, border: '1px solid #544cf8', width: mobile ? '90%' : '80%', minWidth: '80%', marginLeft: mobile ? '5%' : '10%', backgroundColor: 'rgba(239,242,249, 0)'}}>
                        <div className={classes.text} style={{fontSize: 20, marginTop: 0, fontFamily: 'arial', textAlign: 'left', width: '100%'}}>
                          <b>Summary</b>
                        </div>
                        <Divider style={{width: mobile ? 'calc(100% - 8px)' : 'calc(100% - 70px)', marginTop: 20}}/>
                        <div style={{width: '100%', marginTop: 20, fontSize: 15, color: 'rgb(129,139,166)'}}>
                          <div style={{textAlign: 'left', width: '100%'}}><b>Location:</b> {props.main.state.formattedAddress}</div>
                          <Divider style={{width: mobile ? 'calc(100% - 8px)' : 'calc(100% - 70px)', marginTop: 20}}/>
                        </div>
                        <div style={{width: '100%', marginTop: 20, fontSize: 15, color: 'rgb(129,139,166)'}}>
                          <div style={{textAlign: 'left', width: '100%'}}><b>Date & Time:</b> {getDateTimeToDisplay()}</div>
                          <Divider style={{width: mobile ? 'calc(100% - 8px)' : 'calc(100% - 70px)', marginTop: 20}}/>
                        </div>
                        <div style={{width: '100%', marginTop: 20, fontSize: 15, color: 'rgb(129,139,166)'}}>
                          {props.main.state.selectedProduct == '0' ? (<div style={{textAlign: 'left', width: '100%'}}><b>Product:</b> FREE 6 IMAGES</div>) : (
                            <div style={{textAlign: 'left', width: '100%'}}><b>Product:</b> {props.main.state.selectedProductData.Public_Product_Name__c}</div>
                          )}
                          <Divider style={{width: mobile ? 'calc(100% - 8px)' : 'calc(100% - 70px)', marginTop: 20}}/>
                        </div>
                        <div style={{width: '100%', marginTop: 20, fontSize: 15, color: 'rgb(129,139,166)'}}>
                          {props.main.state.willBeOnSite && (<div style={{textAlign: 'left', width: '100%'}}><b>Contact on site:</b> {props.main.state.firstName + " " + props.main.state.lastName}</div>)}
                          {!props.main.state.willBeOnSite && (<div style={{textAlign: 'left', width: '100%'}}><b>Contact on site:</b> {props.main.state.whoWillBeOnSiteName}</div>)}
                          <Divider style={{width: mobile ? 'calc(100% - 8px)' : 'calc(100% - 70px)', marginTop: 20}}/>
                        </div>
                        <div style={{width: '100%', marginTop: 20, fontSize: 15, color: 'rgb(129,139,166)'}}>
                          {props.main.state.willBeOnSite && (<div style={{textAlign: 'left', width: '100%'}}><b>Contact phone:</b> {props.main.state.phone}</div>)}
                          {!props.main.state.willBeOnSite && (<div style={{textAlign: 'left', width: '100%'}}><b>Contact phone:</b> {props.main.state.whoWillBeOnSitePhone}</div>)}
                        </div>
                      </div>
                    </Grid>
                    {(props.main.state.selectedProduct != '0' && props.main.state.isPackage != true) ? (
                      <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                        <div style={{marginTop: 20}}>
                          <div className={classes.text} style={{fontSize: 20, marginTop: 5, marginBottom: 20, fontFamily: 'arial'}}>
                            <b>
                            {props.main.state.selectedProduct == '0' ? (<div>FREE PHOTO SHOOT! (6 IMAGES)</div>) : (
                              <div>Total payment - {props.main.state.selectedProductData.Shooting_Price__c} ILS ({(props.main.state.selectedProductData.Shooting_Price__c * 1.17).toFixed(2)} + VAT)</div>
                            )}
                              <br/>
                              <div style={{color: '#544cf8', marginTop: -20}}>
                              You will be charged automatically after the shoot
                              </div>
                            </b>
                          </div>
                          {props.main.state.selectedProduct == '0' ? (<div style={{width: '90%', height: 260, marginTop: 10}}></div>) : (
                            <iframe style={{width: '90%', height: 260, marginTop: 10, frameBorder: 0, borderRadius: 0, border: 0}} src={"https://direct.tranzila.com/wesnapp/iframenew.php?sum=" + (props.main.state.selectedProductData.Shooting_Price__c * 1.17).toFixed(2) + "&cred_type=1&tranmode=VK&u71=1&nologo=1&currency=1&hidesum=1&buttonLabel=Payment&trButtonColor=000000&requestId=" + props.main.state.requestId + "&accountId=" + props.main.state.accountId + "&orderId=" + props.main.state.orderId + "&name=" + props.main.state.firstName + " " + props.main.state.lastName + "&phone=" + props.main.state.phone + "&email=" + props.main.state.email+ "&selectedProduct=" + props.main.state.selectedProduct + "&type=website"}></iframe>
                          )}
                          {
                            // props.main.state.selectedProduct == 12 && (<iframe style={{width: '90%', height: 260, marginTop: 10, frameBorder: 0, borderRadius: 0, border: 0}} src={"https://direct.tranzila.com/wesnapp/iframenew.php?sum=409.5&cred_type=1&tranmode=VK&u71=1&nologo=1&currency=1&hidesum=1&buttonLabel=Payment&trButtonColor=000000&requestId=" + props.main.state.requestId + "&accountId=" + props.main.state.accountId + "&orderId=" + props.main.state.orderId + "&name=" +                    props.main.state.firstName + " " + props.main.state.lastName + "&phone=" + props.main.state.phone + "&email=" + props.main.state.email+ "&selectedProduct=" + props.main.state.selectedProduct + "&type=website"}></iframe>)}
                            // {props.main.state.selectedProduct == 22 && (<iframe style={{width: '90%', height: 260, marginTop: 10, frameBorder: 0, borderRadius: 0, border: 0}} src={"https://direct.tranzila.com/wesnapp/iframenew.php?sum=526.5&cred_type=1&tranmode=VK&u71=1&nologo=1&currency=1&hidesum=1&buttonLabel=Payment&trButtonColor=000000&requestId=" + props.main.state.requestId + "&accountId=" + props.main.state.accountId + "&orderId=" + props.main.state.orderId + "&name=" +   props.main.state.firstName + " " + props.main.state.lastName + "&phone=" + props.main.state.phone + "&email=" + props.main.state.email+ "&selectedProduct=" + props.main.state.selectedProduct + "&type=website"}></iframe>)}
                            // {props.main.state.selectedProduct == 32 && (<iframe style={{width: '90%', height: 260, marginTop: 10, frameBorder: 0, borderRadius: 0, border: 0}} src={"https://direct.tranzila.com/wesnapp/iframenew.php?sum=702&cred_type=1&tranmode=VK&u71=1&nologo=1&currency=1&hidesum=1&buttonLabel=Payment&trButtonColor=000000&requestId=" + props.main.state.requestId + "&accountId=" + props.main.state.accountId + "&orderId=" + props.main.state.orderId + "&name=" +   props.main.state.firstName + " " + props.main.state.lastName + "&phone=" + props.main.state.phone + "&email=" + props.main.state.email+ "&selectedProduct=" + props.main.state.selectedProduct + "&type=website"}></iframe>)}
                          }
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                        <div style={{marginTop: 15, fontSize: 28, color: '#39DFA1', fontWeight: 'bold'}}>Approved!</div>
                        <div style={{marginTop: 15, fontSize: 22, color: 'black', fontWeight: 'bold'}}>Our team will be in touch with you soon!</div>
                        <div style={{marginTop: 15, fontSize: 22, color: 'black', fontWeight: 'bold'}}>Thank You</div>
                      </Grid>
                    )}
                    </Grid>
              </div>
            )}
              {props.main.state.activeStep == 0 && (
                <div style={{marginTop: mobile ? '-20px' : '20px'}}>
                  <Grid container spacing={24} dir="ltr" justify="center">
                    <Grid item xs={12} sm={11} md={11} xl={8}>
                      <Grid container spacing={1} dir="ltr" justify="center">
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <div style={{marginTop: 20}}>
                          <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>First Name *</div>
                          <input id="first_name" className="OrderInput" onChange={handleChangeText('firstName')} value={props.main.state.firstName}/>
                          {props.main.state.errorFirstName && (
                            <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error first name</div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <div style={{marginTop: 20}}>
                          <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Last Name *</div>
                          <input id="last_name" className="OrderInput" onChange={handleChangeText('lastName')} value={props.main.state.lastName}/>
                          {props.main.state.errorLastName && (
                            <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error last name</div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div style={{marginTop: 20}}>
                          <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Email *</div>
                          <input id="email" className="OrderInput" onChange={handleChangeText('email')} value={props.main.state.email}/>
                          {props.main.state.errorEmail && (
                            <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error email address</div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <div style={{marginTop: 20}}>
                          <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Phone Number *</div>
                          <input id="phone_number" className="OrderInput" onChange={handleChangeText('phone')} value={props.main.state.phone}/>
                          {props.main.state.errorPhoneNumber && (
                            <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error phone number</div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <div style={{marginTop: 20}}>
                          <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Office Name</div>
                          <input id="office_name" className="OrderInput" onChange={handleChangeText('officeName')} value={props.main.state.officeName}/>
                          {props.main.state.errorOfficeName && (
                            <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error last name</div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    </Grid>
                    {
                    // <Grid item xs={0} sm={0} md={0} xl={0}>
                    //   <div style={{height: '100%'}}>
                    //     <img src={photographerImage} style={{width: '0%', float: 'right'}}/>
                    //   </div>
                    // </Grid>
                  }
                    </Grid>
                </div>
              )}
              {props.main.state.activeStep == 1 && (
                <div style={{marginTop: mobile ? -20 : 40}}>
                <Grid container spacing={32} dir="ltr" justify="center">
                  <Grid item xs={12} sm={8} md={6} xl={6}>
                    <Grid container spacing={1} dir="ltr" justify="center">
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div style={{marginTop: 0}}>
                          <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Location</div>
                          <Autocomplete
                          style={{width: 'calc(70% - 30px)', textAlign: 'left', direction: 'ltr'}}
                          onPlaceSelected={(place) => {
                              getCoordinates(place.formatted_address)
                          }}
                          id="address"
                          placeholder="Choose specific address"
                          className="OrderInput"
                          style={{display: 'inline-block', float: 'left', width: '100%'}}
                          types={['address']}


                          componentRestrictions={{country: "il"}}
                          />
                        </div>
                        {props.main.state.errorAddress && (
                          <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Address needs to containe a specific address</div>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <div style={{marginTop: 20}}>
                          <div style={{textAlign: 'left', color: 'rgb(129,139,166)', marginBottom: 8, fontWeight: 'bold'}}>Date</div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                style={{ width: '100%'}}
                                margin="normal"
                                label=""
                                value={props.main.state.formatedDate}
                                onChange={handleDateChangeFrom}
                                minDate={new Date()}
                                id="date"
                                InputProps={{
                                 disableUnderline: true,
                                }}
                                className="OrderInput"
                                style={{padding: 10, display: 'inline-block', width: '100%', marginTop: 0}}
                              />
                            </MuiPickersUtilsProvider>
                        </div>
                        {props.main.state.errorDate && (
                          <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 0}}>Error date</div>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} xl={6}>
                        <div style={{marginTop: 20}}>
                          <div style={{textAlign: 'left', color: 'rgb(129,139,166)', marginBottom: 8, fontWeight: 'bold'}}>Time</div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <TimePicker
                                showTodayButton
                                todayLabel="now"
                                label=""
                                id="time"
                                defaultValue={null}
                                value={props.main.state.formatedTime}
                                // value={new Date()}
                                minutesStep={5}
                                InputProps={{
                                 disableUnderline: true,
                                }}
                                onChange={handleTimeChangeFrom}
                                className="OrderInput"
                                style={{padding: 10, display: 'inline-block', width: '100%', marginTop: 0}}
                              />
                            </MuiPickersUtilsProvider>
                        </div>
                        {props.main.state.errorTime && (
                          <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error time</div>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div style={{float: 'left', marginTop: 15}}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={props.main.state.willBeOnSite}
                                onChange={handleChange('willBeOnSite')}
                                value="willBeOnSite"
                                color="primary"
                              />
                            }
                            label="You will be on site?"
                          />
                        </div>
                      </Grid>
                      {!props.main.state.willBeOnSite && (
                        <Grid item xs={12} sm={12} md={6} xl={6}>
                          <div style={{marginTop: 20}}>
                            <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Contact on site</div>
                            <input id="contact_name" className="OrderInput" onChange={handleChangeText('whoWillBeOnSiteName')} value={props.main.state.whoWillBeOnSiteName}/>
                          </div>
                          {props.main.state.errorWillBeOnSiteName && (
                            <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error name</div>
                          )}
                        </Grid>
                      )}
                      {!props.main.state.willBeOnSite && (
                        <Grid item xs={12} sm={6} md={6} xl={6}>
                          <div style={{marginTop: 20}}>
                            <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Phone Number</div>
                            <input id="contact_phone" className="OrderInput" onChange={handleChangeText('whoWillBeOnSitePhone')} value={props.main.state.whoWillBeOnSitePhone}/>
                          </div>
                          {props.main.state.errorWillBeOnSitePhone && (
                            <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Error phone</div>
                          )}
                        </Grid>
                      )}
                      <Grid container spacing={1} dir="ltr" >
                      <Grid item xs={12} sm={12} md={12} xl={12}>
                          <div style={{textAlign: 'left', marginBottom: 5, marginTop: 5, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Use From Package</div>
                          </Grid>
                          {props.main.state.searchForOpenPackages ? (
                            <div><center><CircularProgress/></center></div>
                          ) : (
                            <Grid item xs={12} sm={12} md={12} xl={12}>
                            <Grid container spacing={1} dir="ltr" >
                            {props.main.state.openPackages.map((item, i) => (
                              <Grid item xs={12} sm={6} md={6} xl={4} lg={4}>
                                  <div style={{display: 'inline-block', width: mobile ? '100%' : '100%', border: '1px solid #544cf8', borderRadius: 20, padding: 15}}>
                                    <div style={{textAlign: 'left', width: '100%', marginTop: mobile ? 15 : 0, borderRadius: 15, fontSize: 18}}>
                                      <div style={{marginTop: 5}}><b>{getProductData(item.singleProductId, 'Public_Product_Name__c')}</b></div>
                                      <div style={{marginTop: 0}}>
                                        {getProductData(item.singleProductId, 'Number_of_Images_for_Customer__c')} Images
                                      </div>
                                      {props.main.props.admin && (
                                        <div style={{marginTop: 5}}>
                                          {getProductData(item.singleProductId, 'Number_of_Images_for_Customer__c')} Images
                                        </div>
                                      )}
                                    </div>
                                      <div style={{marginTop: 10, fontSize: 18}}>
                                      {
                                        // <b>{item.Shooting_Price__c} ILS + VAT</b>
                                      }
                                        <div><b>Balance: {item.balance}</b></div>
                                        <div><b>Already Paid</b></div>
                                      </div>
                                      <div>
                                      {props.main.state.selectedProduct == item.singleProductId ? (
                                        <button
                                          variant="contained"
                                          color="primary"
                                          onClick={handleNext}
                                          className={'selectedPackageButton'}
                                        >
                                          SELECTED
                                        </button>
                                      ) : (
                                        <button
                                          variant="contained"
                                          color="primary"
                                          onClick={handleNext}
                                          className={'selectPackageButton'}
                                          onClick={() => props.main.setState({selectedProduct: item.singleProductId, selectedProductData: getProductData(item.singleProductId, 'all'), isPackage: true, selectedPackage: item})}
                                        >
                                          CHOOSE
                                        </button>
                                      )}
                                      </div>
                                    </div>
                                  </Grid>
                            ))}
                            </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                          <div style={{textAlign: 'left', marginBottom: 5, marginTop: 5, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Choose Product</div>
                          </Grid>
                          {props.main.state.newUser && (
                            <Grid item xs={12} sm={6} md={6} xl={4} lg={4}>
                                <div style={{display: 'inline-block', width: mobile ? '100%' : '100%', border: '1px solid #39DFA1', borderRadius: 20, padding: 15}}>
                                  <div style={{textAlign: 'left', width: '100%', marginTop: mobile ? 15 : 0, height: 50, borderRadius: 15, fontSize: 18}}>
                                    <div style={{marginTop: 5}}><b>Real Estate</b></div>
                                    <div style={{marginTop: 0}}>
                                      6 Images
                                    </div>
                                  </div>
                                    <div style={{marginTop: 10, fontSize: 18}}>
                                      <b>FREE</b>
                                    </div>
                                    {props.main.state.selectedProduct == '0' ? (
                                      <button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={'selectedPackageButton'}
                                      >
                                        SELECTED
                                      </button>
                                    ) : (
                                      <button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={'selectPackageButton'}
                                        onClick={() => props.main.setState({selectedProduct: '0', isPackage: false})}
                                      >
                                        CHOOSE
                                      </button>
                                    )}
                                  </div>
                                </Grid>
                          )}
                          {props.main.state.products.map((item, i) => (
                            <Grid item xs={12} sm={6} md={6} xl={4} lg={4}>
                                <div style={{display: 'inline-block', width: mobile ? '100%' : '100%', border: '1px solid #544cf8', borderRadius: 20, padding: 15}}>
                                  <div style={{textAlign: 'left', width: '100%', marginTop: mobile ? 15 : 0, borderRadius: 15, fontSize: 18}}>
                                    <div style={{marginTop: 5}}><b>{item.Public_Product_Name__c}</b></div>
                                    <div style={{marginTop: 0}}>
                                      {item.Number_of_Images_for_Customer__c} Images
                                    </div>
                                    {props.main.props.admin && (
                                      <div style={{marginTop: 5}}>
                                        {item.Number_of_Images_for_Customer__c} Images
                                      </div>
                                    )}
                                  </div>
                                    <div style={{marginTop: 10, fontSize: 18}}>
                                      <b>{item.Shooting_Price__c} ILS + VAT</b>
                                    </div>
                                    <div>
                                    {props.main.state.selectedProduct == item.Id ? (
                                      <button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={'selectedPackageButton'}
                                      >
                                        SELECTED
                                      </button>
                                    ) : (
                                      <button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={'selectPackageButton'}
                                        onClick={() => props.main.setState({selectedProduct: item.Id, selectedProductData: item, isPackage: false})}
                                      >
                                        CHOOSE
                                      </button>
                                    )}
                                    </div>
                                  </div>
                                </Grid>
                          ))}
                            </Grid>
                        {props.main.state.errorSelectProduct && (
                          <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', float: 'left', marginTop: 8}}>Select a product</div>
                        )}
                      </Grid>
                      </Grid>
                      {4 == 3 && (
                        <Grid item xs={12} sm={6} md={6} xl={6}>
                          <div style={{width: '90%', display: 'inline-block', verticalAlign: 'top', borderRadius: 30, height: 'calc(100% - 5px)', position: 'relative', marginTop: 5}}>
                            <Map google={window.google} lat={props.main.state.lat} lng={props.main.state.lng}/>
                          </div>
                        </Grid>
                      )}
                  </Grid>
                </div>
              )}
            </Typography>
            <br/>
            <br/>
            <Grid container spacing={24} dir="ltr" justify="center" style={{marginTop: mobile ? -15 : 35, marginBottom: 35}}>
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <Button disabled={props.main.state.activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
              </Grid>
              { props.main.state.activeStep != 2 ? (
              <Grid item xs={6} sm={6} md={6} xl={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => props.main.nextStep()}
                  className={classes.button}
                >
                  {props.main.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={6} sm={6} md={6} xl={6}>
              </Grid>
            )}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}
