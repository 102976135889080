import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'rgb(94,44,237)'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const whiteIcon = require("../../Assets/Images/WhiteIcon.png");

export default function ButtonAppBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => props.main.setState({sideMenuOpen: true})}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <img src={whiteIcon} alt="Avatar" className="text" height="auto" width="40"  style={{ position: 'absolute', left: 'calc(50% - 20px)', top: 14}}/>
          </Typography>
          {
          // <Button color="inherit">Login</Button>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}
