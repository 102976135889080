import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import SideMenu from './SideMenu';
import View from './View';
import SmsDialog from './SmsDialog';
import OrderStepper from './OrderStepper';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router";
// import { createBrowserHistory } from "history";

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: 'rgb(127,100,246)',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#39DFA1'
    }
  }
});

class PhotoShoots extends React.Component {

  state = {
    errorPhoneNumber: false,
    phone: '',
    smsDialog: false,
    errorCode: false,
    inputCode: '',
    code: '',
    countryCode: '972',
    auth: false,
    isLoading: false,
    contactId: '',
    errorPhoneNumberNotExists: false
  }

  sendVerificationCode = () => {
    var data = {
      "phone": ("+" + this.state.countryCode + this.state.phone),
      "text": 'WeSnapp verification code: ' + this.state.code
    }
    axios.post('https://wesnapp.co.il' + '/api/sms/send-message',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response);
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  checkIfUserValid = () => {
    var data = {
      "phone": this.state.phone,
    }
    this.setState({isLoading: true})
    axios.post('https://wesnapp.co.il' + '/api/contact/find-user-by-phone-number',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        if (response.data.length > 0) {
          console.log();
          localStorage.setItem("contactId", String(response.data[0].Id));
          localStorage.setItem("accountId", String(response.data[0].AccountId));
          console.log(response.data[0].AccountId);
          this.setState({isLoading: false, contactId: response.data[0].Id, accountId: response.data[0].AccountId}, () => {
            this.setState({errorPhone: false, smsDialog: true, code: this.generateRandomCode()}, () => {
              this.sendVerificationCode()
            })
          })
        } else {
          console.log('not exists');
          this.setState({isLoading: false, errorPhoneNumberNotExists: true})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  verifyCode = () => {
    this.setState({errorCode: false})
    if (this.state.code == this.state.inputCode) {
      this.setState({smsDialog: false})
      // Generate Token
      var token = this.generateToken(64)
      this.updateToken(token)
    } else {
      this.setState({errorCode: true})
    }
  }

  updateToken = (token) => {
    var data = {token: token, accountId: this.state.accountId}
    axios.post('https://wesnapp.co.il' + '/api/contact/update-token',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        if (response.data == true) {
          localStorage.setItem("wesnapp-token", token);
          console.log("reroute");
          this.props.main.setState({auth: true}, () => {
            this.props.history.push("/photo-shoots");
          })
        } else {
          this.setState({auth: false})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  generateRandomCode = () => {
    var code = Math.floor(1000 + Math.random() * 9000)
    return code
  }

  authUser = () => {
    this.setState({errorPhoneNumberNotExists: false, errorPhoneNumber: false})
    if (this.validateMobile(this.state.phone)) {
      // Check if in system
      this.checkIfUserValid()
    } else {
      this.setState({errorPhoneNumber: true})
    }
  }

  generateToken = (n) => {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var token = '';
    for(var i = 0; i < n; i++) {
        token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
  }

  validateMobile = (phone) => {
    console.log(phone);
    var substring1 = " 9725"
    var substring2 = " 97205"
    if (phone.includes(substring1) || phone.includes(substring2)) {
      console.log("@@");
      if (phone.indexOf(substring1) !== -1) {
        console.log(phone);
        phone = phone.replace(" 9725", "05");
        phone = phone.replace("-", "");
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      } else {
        phone = phone.replace(" 97205", "05");
        phone = phone.replace("-", "");
        console.log(phone);
        if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8")) {
          if (phone.length == 10 && (/^\d+$/.test(phone))) {
            this.setState({phone: phone})
            return true
          }
        } else {
          return false;
        }
      }
    } else {
      phone = phone.replace("-", "");
      if (phone[0] == "0" && phone[1] == "5" && (phone[2] == "2" || phone[2] == "0" || phone[2] == "4" || phone[2] == "5" || phone[2] == "8")) {
        if (phone.length == 10 && (/^\d+$/.test(phone))) {
          this.setState({phone: phone})
          return true
        }
      } else {
        return false;
      }
    }
  }

  handleChangeText = name => event => {
    if (name == 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={styles.root} style={{backgroundColor: 'white', borderRadius: '35px 35px 0 0px', minHeight: '100vh'}}>
        {this.state.isLoading == true && (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/><div><div style={{fontFamily: 'arial', fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}></div></div></div>
        )}
        <SmsDialog main={this}/>
        <Grid container spacing={24} justify="center">
          <Grid item xs={12} sm={12} md={10} xl={8} style={{padding: 20}}>
            <div style={{fontSize: 30, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: 43, marginBottom: 30}}>
              <b>Login</b>
            </div>
            <div style={{fontSize: 30, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: 43, marginBottom: 30}}>
              You must pass verification
            </div>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <div style={{marginTop: 20}}>
                <div style={{textAlign: 'left', marginBottom: 8, color: 'rgb(129,139,166)', fontWeight: 'bold'}}>Phone Number</div>
                <input className="OrderInput" onChange={this.handleChangeText('phone')} value={this.state.phone}/>
              </div>
            </Grid>
              <div style={{height: 40}}>
                {this.state.errorPhoneNumber && (
                  <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', marginTop: 8, width: '100%', float: 'left', textAlign: 'left'}}>Error phone number</div>
                )}
                {this.state.errorPhoneNumberNotExists && (
                  <div style={{color: 'red', fontSize: 15, fontFamily: 'arial', marginTop: 8, width: '100%', float: 'left', textAlign: 'left'}}>The given phone number does not exist in the system</div>
                )}
              </div>
              <Button
                variant="contained"
                color="primary"
                style={{float: 'left'}}
                className={classes.button}
                onClick= {()=> this.authUser()}
              >
                Send Me Code
              </Button>
            <div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
