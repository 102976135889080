import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CircularProgress from '@material-ui/core/CircularProgress';
const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

class TemporaryDrawer extends React.Component {

 fullList = side => (
    <div
      style={{width: '100%'}}
      role="presentation"
      onClick={() => this.props.main.setState({selectionOpen: false})}
      onKeyDown={() => this.props.main.setState({selectionOpen: false})}
    >
      <ListItem button key={"Shoot"}>
        <div style={{fontWeight: 'bold', fontSize: 18, fontFamily: 'arial'}}>My selection</div>
      </ListItem>
      <Divider />
      <div style={{width: '100%', marginBottom: 20, fontFamily: 'arial', fontWeight: 'thin'}}>
      {this.props.mainScreen.state.loadingData ? (
        <div style={{height: 60, marginTop: 20, marginLeft: 5}}>
          <div>
            <CircularProgress size={25}/>
          </div>
          <div style={{marginTop: 5}}>
            <b>Loading Data</b>
          </div>
        </div>
      ) : (
        <div style={{marginTop: 20, marginLeft: 5}}>
        <div style={{width: '100%'}}>
          <div style={{fontSize: 22, marginRight: 9, marginLeft: 9, marginTop: 0, textAlign: 'left'}}>
            <b>{this.props.mainScreen.state.selectedImages.length + " / " + this.props.mainScreen.state.numberOfImages + " Photos"}</b>
          </div>
          <div style={{fontSize: 18, color: 'gray', marginRight: 9, marginLeft: 9, marginTop: 3, textAlign: 'left'}}>
            + {this.props.mainScreen.state.selectedImages.length > this.props.mainScreen.state.numberOfImages ? (this.props.mainScreen.state.selectedImages.length - this.props.mainScreen.state.numberOfImages) : (0)} additional x {this.props.mainScreen.state.pricePerExtra} ILS
          </div>
          {this.props.mainScreen.state.selectedImages.length > this.props.mainScreen.state.numberOfImages && (
            <div style={{fontSize: 22, marginRight: 9, marginLeft: 9, marginTop: 10, textAlign: 'left'}}>
              <div style={{fontSize: 20, fontWeight: 'bold', marginBottom: 10}}>Total amount of the additional media</div>
              <div style={{fontSize: 22, color: 'rgba(124,74,250,1.0)'}}><b>{(this.props.mainScreen.state.selectedImages.length - this.props.mainScreen.state.numberOfImages) * this.props.mainScreen.state.pricePerExtra} ILS Tax excluded</b></div>
            </div>
          )}
          {this.props.mainScreen.state.confirmSelect && (
            <div style={{textAlign: 'left'}}>
            <Button onClick={()=> this.confirmSelection()} variant="contained" type="submit" elevation={0} style={{color: 'white', marginTop: 25, height: 48, borderRadius: 24, background: "linear-gradient(to right , #544cf8, #814bfa)", boxShadow: "0px 10px 71px -10px rgba(0,0,0,0.40)"}} >
              Confirm Selection
            </Button>
            </div>
          )}
        </div>
        </div>
      )}
      </div>
    </div>
  );


  render() {
    const { classes, theme } = this.props;
    return (
      <Drawer anchor="top" open={this.props.main.state.selectionOpen} onClose={()=>this.props.main.setState({selectionOpen: false})}>
        {this.fullList('top')}
      </Drawer>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TemporaryDrawer);
