import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Info from '@material-ui/icons/Info';
import SideMenu from './SideMenu';
import View from './View';
import SmsDialog from './SmsDialog';
import OrderStepper from './OrderStepper';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withRouter } from "react-router";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const saleBanner = require("../../Assets/Images/sale-banner.png");
var mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

const noImage = require("../../Assets/Images/no_image.png");

const dateIcon = require("../../Assets/Images/dateIcon.png");
const locationIcon = require("../../Assets/Images/locationIcon.png");
const userIcon = require("../../Assets/Images/userIcon.png");
const imageIcon = require("../../Assets/Images/imageIcon.png");

const styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgb(127,100,246)',
    backgroundColor: 'white'
  },
  button: {
    height: 50,
    marginTop: 15,
    float: 'left',
    borderRadius: 25,
    paddingLeft: 32,
    paddingRight: 32,
    boxShadow: 'none',
    hover: {
      backgroundColor: '#39DFA1'
    }
  }
});

class PhotoShoots extends React.Component {

  state = {
    isLoading: false,
    requests: []
  }

  componentWillMount() {
    if (this.props.main.state.auth == false) {
      this.props.history.push("/login")
    }
  }

  componentDidMount = () => {
    var contactId = localStorage.getItem("contactId")
    if (contactId != null) {
      this.getRelatedRequests(contactId)
    }
  }

  getRelatedRequests = (contactId) => {
    var data = {id: contactId}
    var requests = []
    axios.post('https://wesnapp.co.il' + '/api/request/get-by-contact',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        if (response.data.length > 0) {
          console.log(response.data);
          var responseData = response.data
          // responseData = [responseData[0], responseData[0], responseData[0], responseData[0],]
          // response.data = responseData
          this.setState({requests: responseData}, ()=> {
            for (var i=0; i<response.data.length; i++) {
              responseData[i]['PreviewImage'] = 'loading'
              responseData[i]['OpenDetails'] = false
              responseData[i].Id = responseData[i].Id.slice(0, -3)
            }

            this.setState({requests: responseData}, ()=> {
              for (var i=0; i<responseData.length; i++) {
                this.getPreviewImage(this.state.requests[i].Id, i)
              }
            })
          })
          console.log(requests);
          // this.setState({requests: requests})
        }
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  getPreviewImage = (requestId, i) => {
    console.log('get logo');
    axios.get('https://wesnapp.co.il' + '/py/server/get-preview-image/' + requestId + '-edited' ,{}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
      var updatedArray = this.state.requests
      console.log('before');
      console.log(updatedArray[i]);
      updatedArray[i].PreviewImage = response.data
      console.log("after");
      console.log(updatedArray);
      this.setState({requests: updatedArray})
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  handleChangeText = name => event => {
    if (name == 'phone') {
      this.setState({ [name]: event.target.value.replace('-', '') });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  getFormatedDate = (date) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var d = new Date(date);
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
        min = "0" + min;
    }
    var ampm = "am";
    if( hr > 12 ) {
        hr -= 12;
        ampm = "pm";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    var x = document.getElementById("time");
    return day + " " + hr + ":" + min + ampm + " " + date + " " + month + " " + year;
  }

  openCloseDetails = (e,i) => {
    if (!e) var e = window.event;
     e.cancelBubble = true;
     if (e.stopPropagation) e.stopPropagation();

    console.log('@!@#')
    console.log(i);
    var requests = this.state.requests
    requests[i].OpenDetails = !requests[i].OpenDetails
    this.setState({requests: requests})

  }

  goToShootPage = (item) => {
    window.event.stopPropagation();
    this.props.history.push("/shoot?id=" + item.Id)
    window.event.stopPropagation();
  }

  changeStatusFormat = (status) => {
    var status_to_present = ''
    var color = '#FF8404'
    if (
        status == 'Basic details' ||
        status == 'Full details' ||
        status == 'Product selected' ||
        status == 'Summary approved' ||
        status == 'Pending for match'
      ) {
        status_to_present = 'Pending for match'
      }
      else if (
          status == 'Matched'
        ) {
          status_to_present = 'Pending for shoot'
        } else if (
          status == 'Image Upload Pending' ||
          status == 'Images Uploaded' ||
          status == 'AT Editing Pending' ||
          status == 'AT Editing Issue' ||
          status == 'AT Editing Complete' ||
          status == 'In Editing process' ||
          status == 'AT In Editing Process'
        ) {
          status_to_present = 'In editing'
        } else if (status == 'Editing Complete / Waiting For Selection') {
          status_to_present = 'Ready for selection'
        } else if (status == 'Process Completed') {
          status_to_present = 'Completed'
          color = '#40b88b'
        } else if (
          status == 'Shooting Canceled By Costumer' ||
          status == 'Shooting Canceled By WeSnapp'
        ) {
          color = '#D43600'
          status_to_present = 'Shooting canceled'
        } else if (
          status == 'Shooting Delayed By Costumer' ||
          status == 'Shooting Delayed By WeSnapp'
        ) {
          color = '#D43600'
          status_to_present = 'Shooting delayed'
        }
    return (
      <div style={{ display: 'inline-block', userSelect: 'none', position: 'absolute', top: 15, right: 15, backgroundColor: color, fontSize: 14, borderRadius: 8, padding: 4, color: 'white'}}>{status_to_present}</div>
    )
  }

  render() {
    const { classes, theme } = this.props;

    if (this.props.main.state.auth == false) {
      this.props.history.push("/login")
    }

    return (
        <div className={styles.root} style={{backgroundColor: 'white', borderRadius: '35px 35px 0 0px', marginTop: 10, minHeight: '100vh'}}>
          {this.state.isLoading == true && (
            <div className="loading"><CircularProgress style={{marginTop: 'calc(50vh - 50px)'}}/><div><div style={{fontFamily: 'arial', fontSize: mobile ? 25 : 20, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold'}}></div></div></div>
          )}
          <SmsDialog main={this}/>
          <Grid container spacing={2} style={{padding: 30}} justifyContent="center" justify="center">
            <Grid item xs={12} sm={12} md={12} xl={12}>
            {!mobile && (
              <button style={{display: 'inline-block', cursor: 'pointer', marginLeft: 0}} className="orderButton" onClick={ ()=> {this.props.history.push('/order'); }}
              style={{
                // border: '1px solid rgb(94,44,237)',
                // boxShadow: '0px 10px 71px -10px rgba(0,0,0,0.10)',
                borderRadius: 24,
                // borderColor: 'rgb(14,209,179)',
                cursor: 'pointer',
                marginLeft: 0,
                display: 'inline-block',
                color: 'white',
                backgroundColor: '#39DFA1' ,
                height: 50,
                // border: '1px solid green'
                width: 100,
                marginTop: 5,
                float: 'left'
              }}>
                New Order
              </button>
            )}
            {!mobile && (
              <img src={saleBanner} onClick={()=> window.open('http://bit.ly/2P1UgoQ','_blank')} style={{height: 55, width: 'auto', display: 'inline-block', cursor: 'pointer'}} />
            )}
            {3 == 4 && (
              <div style={{color: 'rgb(129,139,166)', fontFamily: 'arial', backgroundColor: 'rgb(239,242,249)', height: 50, width: 50, fontWeight: 'bold', fontSize: 22, display: 'inline-block', borderRadius: 25, paddingTop: 10, marginTop: 5, verticalAlign: 'top', marginLeft: 15, float: 'right'}}>
                <center>
                  {this.state.avatar}
                </center>
              </div>
            )}
            <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: mobile ? 0 : 18, marginBottom: mobile ? 0 : 30}}>
                <b>Photo</b> Shoots
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={11} xl={9}>
              <Grid container spacing={2} style={{padding: mobile ? 0 : 30}}>
                {this.state.requests.map((item, i) => (
                  <Grid item xs={12} sm={6} md={4} xl={3} onClick={() => this.goToShootPage(item)} style={{cursor: 'pointer'}}>
                    {
                    // <Link to={"/shoot?id=" + item.Id} style={{color:'white', fontWeight: 'bold', fontSize: 18}}>
                    }
                    <div style={{height: mobile ? 330 : 310, width: '100%', backgroundColor: 'rgb(239,242,249)', borderRadius: 15}} onClick={() => this.goToShootPage(item)} className={'shootBox'}>
                      {(item.PreviewImage != "" && item.PreviewImage != "loading") && (
                        <div style={{width: '100%', marginLeft: '0%', "hover": {transform: 'scale(1.3)'}, borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + item.PreviewImage + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', cursor: 'pointer'}}>
                        <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: 'rgba(94,44,237,1)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> this.openCloseDetails(e,i)} className={"infoButton"}>i</div>
                        <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 78, userSelect: 'none'}}>#{item.Name}</div>
                        {this.changeStatusFormat(item.Status__c)}
                          {item.OpenDetails && (
                            <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                              <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                              <div style={{position: 'relative', top: '26%', fontSize: 16}}>{item.Photographer_Name__c}</div>
                              <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {item.Paid__c ? ('Yes') : ('No')}</div>
                              <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                              <div style={{position: 'relative', top: '36%', fontSize: 16}}>{item.Public_Product_Name__c}</div>
                            </div>
                          )}
                        </div>
                      )}
                      {(item.PreviewImage == "" && item.PreviewImage != "loading") && (
                        <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundColor: "rgba(0,0,0,0.7)"}}>
                        <div style={{ display: 'inline-block', position: 'absolute', bottom: -20, right: 15, backgroundColor: 'rgba(94,44,237,1)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 40, height: 40, borderRadius: 20, marginTop: 5, fontSize: 20, fontWeight: 'bold', fontFamily: '"Times New Roman", Times, serif', cursor: 'pointer', userSelect: 'none'}} onClick={(e)=> this.openCloseDetails(e,i)} className={"infoButton"}>i</div>
                          <div style={{ display: 'inline-block', position: 'absolute', top: 15, left: 15, backgroundColor: 'rgba(0,0,0,0.8)', fontSize: 14, borderRadius: 8, padding: 4, color: 'white', width: 78}}>#{item.Name}</div>
                          {this.changeStatusFormat(item.Status__c)}
                          <img style={{position: 'absolute', top: 'calc(50% - 35px)', width: 70, height: 70, left: 'calc(50% - 35px)'}} src={noImage}/>
                          {item.OpenDetails && (
                            <div style={{width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.95)'}}>
                              <div style={{position: 'relative', top: '24%', fontSize: 16, fontWeight: 'bold'}}>Photographer</div>
                              <div style={{position: 'relative', top: '26%', fontSize: 16}}>{item.Photographer_Name__c}</div>
                              <div style={{position: 'relative', top: '30%', fontSize: 16}}><b>Paid:</b> {item.Paid__c ? ('Yes') : ('No')}</div>
                              <div style={{position: 'relative', top: '34%', fontSize: 16, fontWeight: 'bold'}}>Product</div>
                              <div style={{position: 'relative', top: '36%', fontSize: 16}}>{item.Public_Product_Name__c}</div>
                            </div>
                          )}
                        </div>
                      )}
                      {(item.PreviewImage == "loading" && item.OpenDetails != true) && (
                        <div style={{width: '100%', marginLeft: '0%', borderRadius: '18px 18px 0 0',position: 'relative', top: 0, zIndex: 2, height: mobile ? 240 : 220, backgroundImage: "url('" + item.PreviewImage + "')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%'}}>
                          <CircularProgress style={{marginTop: '38%'}}/>
                        </div>
                      )}
                      <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color:'black'}}>{item.ShootingStreet__c}, {item.ShootingCity__c}</div>
                      <div style={{width: '100%', marginTop: 15, marginLeft: 15, textAlign: 'left', fontSize: 15, color: 'black'}}>
                      {
                        this.getFormatedDate(item.Shooting_Date_Time__c)
                      }
                      </div>
                    </div>
                    {
                    // <Button style={{height: mobile ? 45 : 60, marginTop: 0, width: '100%', backgroundColor: 'rgb(94,44,237)', borderRadius: mobile ? '0 0 22.5px 22.5px' : '0 0 30px 30px', position: 'relative', top: -50}}>
                    //   <Link to={"/shoot?id=" + item.Id} style={{color:'white', fontWeight: 'bold', fontSize: 18}}>To Shoot</Link>
                    // </Button>
                    }
                    {
                      // </Link>
                    }
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
    );
  }
}

PhotoShoots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(PhotoShoots));
