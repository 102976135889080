import React, { Component } from 'react';
import Login from './Components/LoginPage.js';
import Template from './Components/Pages/Template.js';
import LandingPage from './Components/Pages/LandingPage.js';
import { Route, Redirect } from 'react-router';
import { Switch } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from "@material-ui/core/CssBaseline";
import Cookies from 'universal-cookie';
import axios from 'axios';
import './App.css';
import Order from './Components/Pages/Order';

const font = "'Lato', sans-serif";
//f6f7f8
const theme = createMuiTheme({
  fontFamily: font,
  palette: {
    background: {
      // default: "rgb(249,250,252)",
      // default: 'rgb(127,100,246)'
      // default: 'rgb(94,44,237)'
      default: 'white'
    },
    primary: {
      main: '#544cf8',
      green: '#ffffff'
    },
    secondary: {
      main: '#544cf8',
    },
    green: {
      main: 'rgb(120,195,17)',
    },
  },
});

class App extends Component {

  state = {
    present: false
  }

  componentDidMount() {
    // if (!window.location.href.includes('?page=')) {
    //   window.history.pushState(null, '', '/order'); window.location.reload()
    // }
  }

  generateFooter = () => {
    return (
      <div style={{width: '100%', bottom: 0, backgroundColor: 'rgb(255,255,255)', height: 120}}>
        <div style={{width: '100%', height: 1, backgroundColor: 'rgb(240,240,240)'}}></div>
        <div style={{color: 'black', marginTop: 35}}>© 2019 WeSnapp. All rights reserved.</div>
        <div style={{color: 'black', marginTop: 12}}>
        <b><span><a style={{color: 'rgb(135,142,225)'}} target="_blank" href="https://wesnapp.co.il/Terms">Home</a></span></b>
        <b><span><a style={{color: 'rgb(135,142,225)', marginLeft: 15}} target="_blank" href="https://wesnapp.co.il/Terms">Terms & Conditions</a></span></b>
        </div>
      </div>
    )
  };

  render() {
    const cookies = new Cookies();
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
            {this.state.present == true && (
              {
                // <div className="loading"><img src={require('./Assets/Images/wesnappLogoBlack.png')} style={{height: 30, marginTop: 'calc(40vh - 15px)'}}/></div>
              }
            )}
            <div class="wrapper">
              {
              <BrowserRouter forceRefresh={true}>
                <Template/>
              </BrowserRouter>
              }
              {
              // <Order/>
              }
              <div class="push"></div>
            </div>
            {
           // <footer class="footer">{this.generateFooter()}</footer>
         }
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
